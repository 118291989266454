import { object } from '../typing/object.js';
import { string } from '../typing/string.js';
import { TypeOf } from '../typing/type-of.js';

export const RuntimeDeclaration = object('RuntimeDeclaration', {
  source: string(),
  exportName: string(),
});

export type RuntimeDeclaration = TypeOf<typeof RuntimeDeclaration>;
