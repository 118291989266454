import {
  StateDefinitions,
  TypeOfStateDeclaration,
} from '@aion/core/storage/state-declaration.js';
import { Lazy } from '@aion/core/management/lazy.js';
import { IDBPDatabase } from 'idb';
import {
  StorageInterface,
  TransactionClient,
  TransactionStateClient,
} from '@aion/core/storage/storage-interface.js';
import {
  DeleteType,
  PatchType,
  StorageClient,
  UpsertType,
} from '@aion/core/storage/storage-client.js';
import { SnapshotClient } from '@aion/core/storage/snapshot-client.js';
import { getOrFail } from '@aion/core/realm/get-or-fail.js';
import { TransactionOperation } from '@aion/core/storage/transaction-operation.js';
import { apply } from './apply.js';
import { createIndexeddbStorageClient } from './create-indexeddb-storage-client.js';
import { createIndexeddbSnapshotClient } from './create-indexeddb-snapshot-client.js';
import { getStateKeys } from '@aion/core/runtime/get-state-data-keys.js';

export function createIndexeddbStorage<TState extends StateDefinitions>(
  db: Lazy<IDBPDatabase>,
  definition: TState,
): StorageInterface<TState> {
  const storageClient: {
    [key: string]: StorageClient<any>;
  } = {};
  const snapshotClient: {
    [key: string]: SnapshotClient<any>;
  } = {};

  for (const [key, type] of Object.entries(definition)) {
    storageClient[key] = createIndexeddbStorageClient(db, key, type);
    snapshotClient[key] = createIndexeddbSnapshotClient(db, key, type);
  }

  return {
    state<K extends string & keyof TState>(name: K): StorageClient<TState[K]> {
      return getOrFail(storageClient, name);
    },
    snapshot<K extends string & keyof TState>(
      name: K,
    ): SnapshotClient<TState[K]> {
      return getOrFail(snapshotClient, name);
    },
    async transaction<T>(
      fn: (client: TransactionClient<TState>) => Promise<T>,
    ): Promise<T> {
      const operations: TransactionOperation[] = [];
      const result = await fn({
        snapshot<K extends string & keyof TState>(
          name: K,
        ): SnapshotClient<TState[K]> {
          // TODO change snapshot behavior for others, snapshot does not contain trx modified state

          return getOrFail(snapshotClient, name);
        },
        state<K extends string & keyof TState>(
          state: K,
        ): TransactionStateClient<TState[K]> {
          return {
            async patch(val: PatchType<TState[K]>): Promise<void> {
              operations.push({
                state,
                type: 'patch',
                data: val,
                keys: getStateKeys(definition[state], val),
              });
            },
            async delete(id: DeleteType<TState[K]>): Promise<void> {
              operations.push({
                state,
                type: 'delete',
                keys: getStateKeys(definition[state], id),
              });
            },
            async upsert(val: UpsertType<TState[K]>): Promise<void> {
              operations.push({
                state,
                type: 'upsert',
                data: val,
                keys: getStateKeys(definition[state], val),
              });
            },
            async insert(
              val: TypeOfStateDeclaration<TState[K]>,
            ): Promise<void> {
              operations.push({
                state,
                type: 'insert',
                data: val,
                keys: getStateKeys(definition[state], val),
              });
            },
          };
        },
      });
      await apply(db, definition, operations);
      return result;
    },
  };
}
