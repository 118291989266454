import { Lazy } from './lazy.js';

export function createLazy<T>(fn: () => Promise<T>): Lazy<T> {
  let value: Promise<T> | null = null;
  return {
    resolve() {
      if (!value) {
        value = fn();
      }
      return value;
    },
    async close(fn: (val: T) => Promise<void> | void): Promise<void> {
      if (value) {
        await fn(await value);
        value = null;
      }
    },
  };
}
