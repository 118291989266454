import { Type } from './type.js';
import { SafeParseReturnType } from './safe-parse-return-type.js';
import { TypeDescription } from './type-description.js';

export function createType<Output, Desc extends TypeDescription>(options: {
  description: Desc;
  validate: (val: unknown, path: string[]) => SafeParseReturnType<Output>;
}): Type<Output, Desc> {
  return {
    _output: null as any,
    description: options.description,
    safeParse(data: unknown, path: string[]): SafeParseReturnType<Output> {
      return options.validate(data, path);
    },
  };
}
