import { ValidateError } from './validate-error.js';
import { createType } from './create-type.js';
import { StringTypeDescription } from './string-type-description.js';
import { Type } from './type.js';

export const string: () => Type<string, StringTypeDescription> = () =>
  createType<string, StringTypeDescription>({
    description: { type: 'string' },
    validate: (val, path) => {
      if (typeof val === 'string') {
        return { success: true, data: val };
      } else {
        return {
          success: false,
          error: new ValidateError('not a valid string', path, val),
        };
      }
    },
  });
