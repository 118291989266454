import { object } from '../typing/object.js';
import { string } from '../typing/string.js';
import { record } from '../typing/record.js';
import { optional } from '../typing/optional.js';
import { union } from '../typing/union.js';
import { array } from '../typing/array.js';
import { TypeOf } from '../typing/type-of.js';
import { literal } from '../typing/literal.js';
import { Secret } from './secret.js';

export const OpenIDProvider = object('OpenIDProvider', {
  type: literal('openid'),
  clientId: string(),
  clientSecret: union('OpenIDClientSecret', [string(), Secret]),
  jwks: object('OpenIDProviderJwks', {
    url: string(),
  }),
  token: object('OpenIDProviderToken', {
    url: string(),
    formData: record(string(), string()),
  }),
  authorize: object('OpenIDProviderAuthorize', {
    url: string(),
    queryParams: record(string(), string()),
  }),
  issuer: union('OpenIDIssuer', [string(), array(string())]),
  audience: optional(union('OpenIDAudience', [string(), array(string())])),
});

export type OpenIDProvider = TypeOf<typeof OpenIDProvider>;
