import { createType } from './create-type.js';
import { NumberTypeDescription } from './number-type-description.js';
import { ValidateError } from './validate-error.js';

export const number = () =>
  createType<number, NumberTypeDescription>({
    description: { type: 'number' },
    validate: (val, path) => {
      if (typeof val === 'number') {
        return { success: true, data: val };
      } else {
        return {
          success: false,
          error: new ValidateError('not a valid number', path, val),
        };
      }
    },
  });
