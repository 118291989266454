import { Pipe, PipeTransform } from '@angular/core';

const minute = 60,
  hour = minute * 60,
  day = hour * 24,
  week = day * 7;

@Pipe({
  name: 'humanizeDate',
})
export class HumanizeDatePipe implements PipeTransform {
  transform(value: Date, ...args: unknown[]): string {
    const delta = Math.round((new Date().getTime() - value.getTime()) / 1000);

    if (delta < 30) {
      return 'just then.';
    } else if (delta < minute) {
      return delta + ' seconds ago.';
    } else if (delta < 2 * minute) {
      return 'a minute ago.';
    } else if (delta < hour) {
      return Math.floor(delta / minute) + ' minutes ago.';
    } else if (Math.floor(delta / hour) == 1) {
      return '1 hour ago.';
    } else if (delta < day) {
      return Math.floor(delta / hour) + ' hours ago.';
    } else if (delta < day * 2) {
      return 'yesterday';
    } else if (delta < week) {
      return Math.floor(delta / day) + ' days ago.';
    } else {
      return Math.floor(delta / week) + ' weeks ago.';
    }
  }
}
