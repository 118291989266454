import { object } from '../../typing/object.js';
import { literal } from '../../typing/literal.js';
import { string } from '../../typing/string.js';
import { record } from '../../typing/record.js';
import { union } from '../../typing/union.js';
import { TypeOf } from '../../typing/type-of.js';
import { StateValue } from '../state-value.js';

export const StateUpdatePatch = object('StateUpdatePatch', {
  type: literal('patch'),
  state: string(),
  keys: record(string(), StateValue),
  data: record(string(), StateValue),
});
export type StateUpdatePatch = TypeOf<typeof StateUpdatePatch>;

export const StateUpdateDelete = object('StateUpdateDelete', {
  type: literal('delete'),
  state: string(),
  keys: record(string(), StateValue),
});
export type StateUpdateDelete = TypeOf<typeof StateUpdateDelete>;

export const StateUpdateInsert = object('StateUpdateInsert', {
  type: literal('insert'),
  state: string(),
  keys: record(string(), StateValue),
  data: record(string(), StateValue),
});
export type StateUpdateInsert = TypeOf<typeof StateUpdateInsert>;

export const StateUpdateUpsert = object('StateUpdateUpsert', {
  type: literal('upsert'),
  state: string(),
  keys: record(string(), StateValue),
  data: record(string(), StateValue),
});
export type StateUpdateUpsert = TypeOf<typeof StateUpdateUpsert>;

export const StateUpdate = union('StateUpdate', [
  StateUpdatePatch,
  StateUpdateDelete,
  StateUpdateInsert,
  StateUpdateUpsert,
]);
export type StateUpdate = TypeOf<typeof StateUpdate>;
