import { object } from '@aion/core/typing/object.js';
import { string } from '@aion/core/typing/string.js';

export const AuthRefresh = object('AuthRefresh', {
  tenant: string(),
  realm: string(),
  provider: string(),
  username: string(),
  refreshToken: string(),
});
