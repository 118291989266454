import { object } from '@aion/core/typing/object.js';
import { string } from '@aion/core/typing/string.js';
import { number } from '@aion/core/typing/number.js';

export const TokenResponse = object('TokenResponse', {
  id_token: string(),
  refresh_token: string(),
  scope: string(),
  expires_in: number(),
  username: string(),
});
