import {
  StateDefinition,
  StateIndex,
  StateIndices,
  TypeOfStateDeclaration,
} from '../state-declaration.js';
import {
  FilterKey,
  FilterStateIndex,
  FilterValue,
  SnapshotIndexClient,
} from '../snapshot-index-client.js';
import { BrowseResult, RangeResult } from '../browse-result.js';
import { ObserveRangeOptions } from './observe-range-options.js';

import { IterateOptions } from './iterate-options.js';
import { ObserveWatcher } from './observer.js';
import { createLazy } from '../../management/create-lazy.js';
import { browse } from '../browse.js';
import { CustomError } from '../../custom-error.js';
import { StateValue } from '../state-value.js';

export interface ObservedIndexClient<
  TState extends StateDefinition<any, any, any>,
  TIndex extends StateIndex<any, any>,
> {
  nextKey: FilterKey<TIndex> | null;

  observeRange(
    bookmark: FilterValue<TState, TIndex> | null,
    opts: ObserveRangeOptions,
  ): AsyncGenerator<BrowseResult<TypeOfStateDeclaration<TState>>>;

  iterate(
    bookmark: FilterValue<TState, TIndex> | null,
    opts: IterateOptions,
  ): AsyncGenerator<RangeResult<TypeOfStateDeclaration<TState>>>;

  filter(
    key: FilterKey<TIndex>,
    value: FilterValue<TState, TIndex>,
  ): ObservedIndexClient<TState, FilterStateIndex<TIndex>>;
}

export function observedIndexClient<
  TState extends StateDefinition<any, any, StateIndices<any>>,
  TIndex extends StateIndex<any, any>,
>(
  client: SnapshotIndexClient<TState, TIndex>,
  observer: ObserveWatcher<TState>,
  definition: TState,
  stateName: string,
  indexName: string,
  filter: Record<string, StateValue>,
): ObservedIndexClient<TState, TIndex> {
  const indexDefinition = definition.indices[indexName];
  if (!indexDefinition) {
    throw new CustomError('invalid index', null, {
      indexName,
    });
  }

  return {
    nextKey: client.nextKey,
    observeRange(
      bookmark: FilterValue<TState, TIndex> | null,
      opts: ObserveRangeOptions,
    ): AsyncGenerator<BrowseResult<TypeOfStateDeclaration<TState>>> {
      const take = opts?.take ?? 10;
      return observer.watchBatch(
        createLazy(() => browse(client.range(bookmark, opts), take)),
        {
          bookmark,
          orderDirection: opts?.dir ?? 'next',
          filter,
          take,
          keys: indexDefinition.fields,
          signal: opts.signal,
        },
      );
    },
    async *iterate(
      bookmark: FilterValue<TState, TIndex> | null,
      opts: IterateOptions,
    ): AsyncGenerator<RangeResult<TypeOfStateDeclaration<TState>>> {
      yield* observer.watchIterator(client.range(bookmark, opts), {
        bookmark,
        keys: indexDefinition.fields,
        orderDirection: opts?.dir ?? 'next',
        filter,
        signal: opts.signal,
      });
    },
    filter(
      key: FilterKey<TIndex> & string,
      value: FilterValue<TState, TIndex>,
    ): ObservedIndexClient<TState, FilterStateIndex<TIndex>> {
      return observedIndexClient(
        client.filter(key, value),
        observer,
        definition,
        stateName,
        indexName,
        { ...filter, [key]: value },
      );
    },
  };
}
