import { JwtSessionData } from './jwt-session-data.js';
import { JwtSession } from './jwt-session.js';
import { nowInSeconds } from './now-in-seconds.js';
import { createEmitter } from '@aion/core/emitter/create-emitter.js';
import { CustomError } from '@aion/core/custom-error.js';
import { RpcClient } from '../api/create-rpc-client.js';

export function reusableResolvable<T>() {
  let promise: Promise<T> | null = null;
  return {
    resolve(fn: () => Promise<T>): Promise<T> {
      if (!promise) {
        promise = fn().finally(() => {
          promise = null;
        });
      }
      return promise;
    },
  };
}

export function createJwtSessionHandler(
  rpcClient: RpcClient,
  opts: { data: JwtSessionData; abort: AbortController },
): JwtSession {
  let token = opts.data;
  let invalidResponse = false;

  const errorHook = createEmitter();
  const reusableRefresh = reusableResolvable<string | undefined>();

  function handleSessionResponse(
    data:
      | undefined
      | {
          id_token: string;
          refresh_token: string;
          expires_in: number;
        },
  ): string | undefined {
    if (!data) {
      if (!invalidResponse) {
        invalidResponse = true;
        errorHook.emit(new CustomError('invalid session', null, {}));
      }
      return undefined;
    }
    token = {
      accessToken: data.id_token,
      refreshToken: data.refresh_token,
      exp: nowInSeconds() + data.expires_in,
      provider: token.provider,
      realm: token.realm,
      tenant: token.tenant,
      username: token.username,
    };

    return token.accessToken;
  }

  return {
    async on(type: 'error', cb: (err: unknown) => void): Promise<void> {
      return errorHook.on(cb);
    },
    async resolveToken(): Promise<string | undefined> {
      if (opts.abort.signal.aborted) {
        return undefined;
      }

      if (invalidResponse) {
        return undefined;
      }

      const now = nowInSeconds();

      if (token && token.exp > now + 20) {
        return token.accessToken;
      }

      return reusableRefresh.resolve(async () => {
        try {
          const response = await rpcClient['auth.refresh'].call(
            {
              tenant: token.tenant,
              realm: token.realm,
              provider: token.provider,
              refreshToken: token.refreshToken,
              username: token.username,
            },
            opts.abort.signal,
          );

          return handleSessionResponse(response.result);
        } catch (e) {
          if (e instanceof CustomError) {
            errorHook.emit(e);
          }
          throw e;
        }
      });
    },
    async close(): Promise<void> {
      opts.abort.abort();
    },
  };
}
