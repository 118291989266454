import { Component, inject, Input, OnInit } from '@angular/core';
import { TableRowComponent } from '../table-row/table-row.component';

@Component({
  selector: 'app-table-cell',
  templateUrl: './table-cell.component.html',
  styleUrls: ['./table-cell.component.scss'],
})
export class TableCellComponent implements OnInit {
  @Input()
  name!: string;

  @Input()
  width = 'max-content';

  private row = inject(TableRowComponent);

  ngOnInit() {
    this.row.registerCell({
      name: this.name,
      width: this.width,
    });
  }
}
