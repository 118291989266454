import { object } from '../typing/object.js';
import { string } from '../typing/string.js';
import { TypeOf } from '../typing/type-of.js';
import { array } from '../typing/array.js';
import { StreamOutput } from './stream-output.js';
import { record } from '../typing/record.js';
import { StreamState } from './stream-state.js';
import { RuntimeDeclaration } from './runtime-declaration.js';
import { StreamEventDeclaration } from './stream-event-declaration.js';

export const StreamDeclaration = object('StreamDeclaration', {
  realm: string(),
  pattern: string(),
  version: string(),
  runtime: RuntimeDeclaration,
  outputs: array(StreamOutput),
  events: record(string(), StreamEventDeclaration),
  states: record(string(), StreamState),
});

export type StreamDeclaration = TypeOf<typeof StreamDeclaration>;
