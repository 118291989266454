import {
  StateDefinition,
  StateDefinitions,
  StateIndices,
  StateKeyValues,
  TypeOfStateDeclaration,
} from '../storage/state-declaration.js';
import { InstanceIdentifier } from './instance-identifier.js';
import { InstanceStorage } from './instance-storage.js';
import { StorageBuilder } from '../storage/tracker/storage-builder.js';
import { createMultiStorage } from '../storage/create-multi-storage.js';
import { StateProcessorState } from './create-state-processor.js';
import { ManagementState } from './management-state.js';
import { DefinitionIdentifier } from '../realm/definition-identifier.js';
import { object } from '../typing/object.js';
import { buildValidator } from '../typing/build-validator.js';
import { string } from '../typing/string.js';
import { Type } from '../typing/type.js';
import {
  TrackedStorage,
  TrackedView,
} from '../storage/tracker/tracked-state.js';
import { SnapshotClient } from '../storage/snapshot-client.js';
import { ObservedClient } from '../storage/observer/observed-client.js';
import {
  FilterKey,
  FilterStateIndex,
  FilterValue,
  RangeOptions,
  SnapshotIndexClient,
} from '../storage/snapshot-index-client.js';
import { BrowseResult, RangeResult } from '../storage/browse-result.js';
import { ObserveSingleOptions } from '../storage/observer/observe-single-options.js';
import { ObserveRangeOptions } from '../storage/observer/observe-range-options.js';
import { IterateOptions } from '../storage/observer/iterate-options.js';
import { ObservedIndexClient } from '../storage/observer/observed-index-client.js';
import {
  DeleteType,
  PatchType,
  StorageClient,
  UpsertType,
} from '../storage/storage-client.js';
import {
  TransactionClient,
  TransactionStateClient,
} from '../storage/storage-interface.js';

export function createDefinitionStorage<TState extends StateDefinitions>(
  identifier: DefinitionIdentifier,
  storageFactory: StorageBuilder,
  state: TState,
) {
  return createMultiStorage(
    storageFactory,
    `${identifier.tenant}:${identifier.realm}:${identifier.pattern}:${identifier.version}`,
    {
      processor: StateProcessorState,
      state: partitionState(state),
      management: ManagementState,
    },
  );
}

function partitionState<TState extends StateDefinitions>(
  state: TState,
): TState {
  return Object.entries(state).reduce<StateDefinitions>((map, [key, value]) => {
    map[key] = {
      type: object(value.type.description.name, {
        ...Object.entries(value.type.description.object).reduce<
          Record<string, Type<any, any>>
        >((obj, [key, prop]) => {
          obj[`partition_${key}`] = buildValidator(prop);
          return obj;
        }, {}),
        tenant: string(),
        realm: string(),
        pattern: string(),
        version: string(),
        name: string(),
      }),
      key: [
        'tenant',
        'realm',
        'pattern',
        'version',
        'name',
        ...value.key.map((k) => `partition_${k}`),
      ],
      indices: Object.entries(value.indices as StateIndices<any>).reduce<
        StateIndices<any>
      >((indexMap, [indexKey, indexValue]) => {
        indexMap[indexKey] = {
          fields: [
            'tenant',
            'realm',
            'pattern',
            'version',
            'name',
            ...(indexValue.fields as string[]).map((k) => `partition_${k}`),
          ],
          unique: indexValue.unique,
        };
        return indexMap;
      }, {}),
    };
    return map;
  }, {}) as TState;
}

export function createInstanceStorage<TState extends StateDefinitions>(
  identifier: InstanceIdentifier,
  storageFactory: StorageBuilder,
  state: TState,
): InstanceStorage<TState> {
  const storage = createDefinitionStorage(identifier, storageFactory, state);
  return {
    identifier,
    storage: storage,
    management: storage.storage('management'),
    stateStorage: getPartitionedStorage(
      storage.storage('state'),
      ['tenant', 'realm', 'pattern', 'version', 'name'],
      {
        tenant: identifier.tenant,
        realm: identifier.realm,
        pattern: identifier.pattern,
        version: identifier.version,
        name: identifier.name,
      },
    ),
    processorStorage: storage.storage('processor'),
  };
}

function mapParitioned(data: any): any {
  return Object.entries(data).reduce<Record<string, any>>(
    (map, [key, data]) => {
      if (key.startsWith('partition_')) {
        map[key.substring('partition_'.length)] = data;
      }
      return map;
    },
    {},
  );
}

export function createPartitionedObservedClient<
  TState extends StateDefinition<any, any, any>,
>(
  state: ObservedClient<TState>,
  partitionKeys: string[],
  partitionValues: Record<string, string>,
): ObservedClient<TState> {
  return {
    async *observe(
      id: StateKeyValues<TState>,
      opts: ObserveSingleOptions,
    ): AsyncGenerator<TypeOfStateDeclaration<TState> | null> {
      for await (const result of state.observe(
        {
          ...id,
          ...partitionValues,
        },
        opts,
      )) {
        yield mapParitioned(result);
      }
    },
    async *observeRange(
      bookmark: StateKeyValues<TState> | null,
      opts: ObserveRangeOptions,
    ): AsyncGenerator<BrowseResult<TypeOfStateDeclaration<TState>>> {
      for await (const result of state.observeRange(
        {
          ...bookmark,
          ...(partitionValues as any),
        },
        opts,
      )) {
        yield {
          bookmark: result.bookmark,
          items: result.items.map((i) => ({
            keys: i.keys,
            data: mapParitioned(i.data),
          })),
        };
      }
    },
    async *iterate(
      bookmark: StateKeyValues<TState> | null,
      opts: IterateOptions,
    ): AsyncGenerator<RangeResult<TypeOfStateDeclaration<TState>>> {
      for await (const result of state.iterate(
        {
          ...bookmark,
          ...(partitionValues as any),
        },
        opts,
      )) {
        yield {
          keys: result.keys,
          data: mapParitioned(result.data),
        };
      }
    },
    index<K extends keyof TState['indices'] & string>(
      index: K,
    ): ObservedIndexClient<TState, TState['indices'][K]> {
      let client = state.index(index);
      for (const key of partitionKeys) {
        client = client.filter(key as any, partitionValues[key] as any);
      }
      return createPartitionedObservedIndex(client);
    },
  };
}

export function createPartitionedIndex<
  TState extends StateDefinition<any, any, any>,
>(client: SnapshotIndexClient<TState, any>): SnapshotIndexClient<TState, any> {
  return {
    nextKey: client.nextKey,
    filter(
      key: FilterKey<any>,
      value: FilterValue<TState, any>,
    ): SnapshotIndexClient<TState, FilterStateIndex<any>> {
      return createPartitionedIndex(client.filter(key, value));
    },
    async *range(
      bookmark: FilterValue<TState, any> | null,
      opts?: Partial<RangeOptions>,
    ): AsyncGenerator<RangeResult<TypeOfStateDeclaration<TState>>> {
      for await (const result of client.range(bookmark, opts)) {
        yield {
          keys: result.keys,
          data: mapParitioned(result.data),
        };
      }
    },
  };
}

export function createPartitionedObservedIndex<
  TState extends StateDefinition<any, any, any>,
>(client: ObservedIndexClient<TState, any>): ObservedIndexClient<TState, any> {
  return {
    nextKey: client.nextKey,
    async *iterate(
      bookmark: FilterValue<TState, any> | null,
      opts: IterateOptions,
    ): AsyncGenerator<RangeResult<TypeOfStateDeclaration<TState>>> {
      for await (const result of client.iterate(bookmark, opts)) {
        yield {
          keys: result.keys,
          data: mapParitioned(result.data),
        };
      }
    },
    filter(
      key: FilterKey<any>,
      value: FilterValue<TState, any>,
    ): ObservedIndexClient<TState, FilterStateIndex<any>> {
      return createPartitionedObservedIndex(client.filter(key, value));
    },
    async *observeRange(
      bookmark: FilterValue<TState, any> | null,
      opts: ObserveRangeOptions,
    ): AsyncGenerator<BrowseResult<TypeOfStateDeclaration<TState>>> {
      for await (const result of client.observeRange(bookmark, opts)) {
        yield {
          bookmark: result.bookmark,
          items: result.items.map((i) => ({
            keys: i.keys,
            data: mapParitioned(i.data),
          })),
        };
      }
    },
  };
}

export function createPartitionedTransactionClient<
  TState extends StateDefinitions,
>(
  state: TransactionClient<TState>,
  partitionKeys: string[],
  partitionValues: Record<string, string>,
): TransactionClient<TState> {
  return {
    state<K extends string & keyof TState>(
      name: K,
    ): TransactionStateClient<TState[K]> {
      return createPartitionedStorageClient(state.state(name), partitionValues);
    },
    snapshot<K extends string & keyof TState>(
      name: K,
    ): SnapshotClient<TState[K]> {
      return createPartitionedSnapshot(
        state.snapshot(name),
        partitionKeys,
        partitionValues,
      );
    },
  };
}

export function createPartitionedStorageClient<
  TState extends StateDefinition<any, any, any>,
>(
  state: StorageClient<TState>,
  partitionValues: Record<string, string>,
): StorageClient<TState> {
  return {
    patch(val: PatchType<TState>): Promise<void> {
      return state.patch({
        ...val,
        ...partitionValues,
      });
    },
    delete(id: DeleteType<TState>): Promise<void> {
      return state.delete({
        ...id,
        ...partitionValues,
      });
    },
    insert(val: TypeOfStateDeclaration<TState>): Promise<void> {
      return state.insert({
        ...val,
        ...partitionValues,
      });
    },
    upsert(val: UpsertType<TState>): Promise<void> {
      return state.upsert({
        ...val,
        ...partitionValues,
      });
    },
  };
}

export function createPartitionedSnapshot<
  TState extends StateDefinition<any, any, any>,
>(
  state: SnapshotClient<TState>,
  partitionKeys: string[],
  partitionValues: Record<string, string>,
): SnapshotClient<TState> {
  return {
    get(
      id: StateKeyValues<TState>,
    ): Promise<TypeOfStateDeclaration<TState> | null> {
      return state.get({
        ...id,
        ...partitionValues,
      });
    },
    index<K extends keyof TState['indices'] & string>(
      index: K,
    ): SnapshotIndexClient<TState, TState['indices'][K]> {
      let client = state.index(index);
      for (const key of partitionKeys) {
        client = client.filter(key as any, partitionValues[key] as any);
      }
      return createPartitionedIndex(client);
    },
    range(
      bookmark: StateKeyValues<TState> | null,
      opts?: Partial<RangeOptions>,
    ): AsyncGenerator<RangeResult<TypeOfStateDeclaration<TState>>> {
      return state.range(
        {
          ...(partitionValues as any),
          ...bookmark,
        },
        opts,
      );
    },
  };
}

export function getPartitionedStorage<TState extends StateDefinitions>(
  storage: TrackedStorage<TState>,
  partitionKeys: string[],
  partitionValues: Record<string, string>,
): TrackedView<TState> {
  return {
    snapshot<K extends string & keyof TState>(
      name: K,
    ): SnapshotClient<TState[K]> {
      return createPartitionedSnapshot(
        storage.snapshot(name),
        partitionKeys,
        partitionValues,
      );
    },
    observe<K extends string & keyof TState>(
      name: K,
    ): ObservedClient<TState[K]> {
      return createPartitionedObservedClient(
        storage.observe(name),
        partitionKeys,
        partitionValues,
      );
    },
  };
}
