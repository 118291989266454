import { createType } from './create-type.js';
import { LiteralTypeDescription } from './literal-type-description.js';
import { ValidateError } from './validate-error.js';

export const literal = <T extends string | number | boolean>(constant: T) =>
  createType<T, LiteralTypeDescription>({
    description: { type: 'literal', constant },
    validate: (val, path) => {
      if (val === constant) {
        return { success: true, data: val as T };
      } else {
        return {
          success: false,
          error: new ValidateError(`not ${constant}`, path, val),
        };
      }
    },
  });
