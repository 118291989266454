import { object } from '../typing/object.js';
import { string } from '../typing/string.js';
import { TypeOf } from '../typing/type-of.js';
import { TypeDescriptionSchema } from '../typing/type-description-schema.js';

export const StreamOutput = object('StreamOutput', {
  name: string(),
  type: TypeDescriptionSchema,
});

export type StreamOutput = TypeOf<typeof StreamOutput>;
