import { StreamEventDeclaration } from '../realm/stream-event-declaration.js';
import { buildValidator } from '../typing/build-validator.js';
import { EventDeclarations } from '../event/event-declarations.js';
import { Type } from '../typing/type.js';
import { TypeDescription } from '../typing/type-description.js';

export function mapEventDeclarations(
  events: Record<string, { description: TypeDescription }>,
): {
  [key: string]: StreamEventDeclaration;
} {
  return Object.entries(events).reduce<Record<string, StreamEventDeclaration>>(
    (map, [key, value]) => {
      map[key] = {
        type: value.description,
      };
      return map;
    },
    {},
  );
}

export function mapEventDefinition(
  state: Record<string, StreamEventDeclaration>,
): EventDeclarations {
  return Object.entries(state).reduce<Record<string, Type<any, any>>>(
    (map, [key, value]) => {
      map[key] = buildValidator(value.type);
      return map;
    },
    {},
  );
}
