import { object } from '@aion/core/typing/object.js';
import { string } from '@aion/core/typing/string.js';
import { TypeOf } from '@aion/core/typing/type-of.js';
import { declareEvent } from '@aion/core/storage/declare-state.js';

export const CreateGroupEvent = declareEvent({
  groupId: string(),
  name: string(),
});
export type CreateGroupEvent = TypeOf<typeof CreateGroupEvent>;
