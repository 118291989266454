import { BrowseResult, RangeResult } from './browse-result.js';

export async function browse<Value>(
  iterator: AsyncGenerator<RangeResult<Value>>,
  limit: number,
): Promise<BrowseResult<Value>> {
  const result: BrowseResult<Value> = {
    bookmark: null,
    items: [],
  };

  for await (const item of iterator) {
    result.items.push(item);
    result.bookmark = item.keys;
    if (result.items.length === limit) {
      return result;
    }
  }

  return result;
}
