import { StorageProvider } from '../../management/storage-provider.js';
import { StateDefinitions } from '../state-declaration.js';
import { TrackedStorage } from './tracked-state.js';
import { createTrackedStorage } from './track-storage.js';
import { createStateObserver } from '../observer/create-observer.js';
import { observedClient, ObservedClient } from '../observer/observed-client.js';
import { StorageBuilder } from './storage-builder.js';
import { Queue } from '../../queue/queue.js';

export function createdStorageBuilder(
  provider: StorageProvider,
  queue: Queue,
): StorageBuilder {
  return {
    open<TState extends StateDefinitions>(
      name: string,
      state: TState,
    ): TrackedStorage<TState> {
      const storage = provider.open(name, state);
      const trackedStorage = createTrackedStorage(name, state, storage, queue);
      const observer = createStateObserver(name, state, queue);

      return {
        ...trackedStorage,
        observe<K extends string & keyof TState>(
          name: K,
        ): ObservedClient<TState[K]> {
          return observedClient(storage, observer.watch(name), state, name);
        },
      };
    },
    close(name: string): Promise<void> {
      return provider.close(name);
    },
    destroy(name: string): Promise<void> {
      return provider.destroy(name);
    },
    async *sessions(): AsyncGenerator<string> {
      for await (const session of provider.sessions()) {
        yield session;
      }
    },
  };
}
