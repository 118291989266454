import { object } from '@aion/core/typing/object.js';
import { string } from '@aion/core/typing/string.js';

export const AuthorizeRequest = object('AuthorizeRequest', {
  tenant: string(),
  realm: string(),
  provider: string(),
  code: string(),
  redirectUri: string(),
});
