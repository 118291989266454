import { object } from '@aion/core/typing/object.js';
import { string } from '@aion/core/typing/string.js';
import { StreamArgs } from './stream-args.js';
import { optional } from '@aion/core/typing/optional.js';
import { date } from '@aion/core/typing/date.js';
import { record } from '@aion/core/typing/record.js';
import { StateValue } from '@aion/core/storage/state-value.js';

export const EventPush = object('PushEvent', {
  realm: string(),
  tenant: string(),
  version: string(),
  pattern: string(),
  args: StreamArgs,
  event: string(),

  id: optional(string()),
  createdAt: optional(date()),
  data: record(string(), StateValue),
  annotations: optional(record(string(), string())),

  token: optional(string()),
});
