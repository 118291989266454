import { inject, Injectable } from '@angular/core';
import { todoRealm } from '../state/realm';
import { createOAuthStorage } from '@aion/browser/oauth-storage.js';
import { createBrowserEnv } from '@aion/browser/create-browser-env';
import { Router } from '@angular/router';
import { environment } from '../environments/environment.js';
import {
  groupStream,
  personalGroupAggregation,
  personalTodoAggregation,
  todoStream,
} from '../state/export.js';
import { createOfflineManagement } from '@aion/client/offline/create-offline-management.js';
import { createIndexeddbProvider } from '@aion/browser/indexeddb-client.js';
import { createRemoteManagement } from '@aion/client/management/create-remote-management.js';

@Injectable({
  providedIn: 'root',
})
export class TodoStateService {
  abort = new AbortController();

  session = createOAuthStorage(todoRealm, {});

  remote = createRemoteManagement({
    url: environment.host,
    tenant: environment.tenant,
    session: this.session,
    env: createBrowserEnv(),
    signal: this.abort.signal,
  });
  //
  // local = createBrowserManagement({
  //   url: environment.host,
  //   tenant: environment.tenant,
  //   session: this.session,
  //   signal: this.abort.signal,
  //
  //   realms: [todoRealm],
  //   streams: [todoStream, groupStream],
  //   aggregations: [personalTodoAggregation, personalGroupAggregation],
  // });

  offline = createOfflineManagement({
    url: environment.host,
    tenant: environment.tenant,
    session: this.session,
    env: createBrowserEnv(),
    signal: this.abort.signal,
    storageProvider: createIndexeddbProvider(),

    realms: [todoRealm],
    streams: [todoStream, groupStream],
    aggregations: [personalTodoAggregation, personalGroupAggregation],
  });

  management = this.remote;

  router = inject(Router);

  sessionEvents = this.remote.session.on('error', async (err) => {
    console.error(err);
    await this.remote.session.logout();
    await this.offline.session.logout();
    await this.router.navigate(['/login']);
  });
}
