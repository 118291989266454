import { object } from '@aion/core/typing/object.js';
import { string } from '@aion/core/typing/string.js';
import { number } from '@aion/core/typing/number.js';
import { TypeOf } from '@aion/core/typing/type-of.js';

export const JwtSessionData = object('JwtSessionData', {
  realm: string(),
  tenant: string(),
  provider: string(),
  accessToken: string(),
  refreshToken: string(),
  username: string(),
  exp: number(),
});
export type JwtSessionData = TypeOf<typeof JwtSessionData>;
