import { ManagementOptions } from './management-options.js';
import { ManagementContext } from './management-context.js';
import { MultiStorage } from '../storage/multi-storage.js';
import { TenantState } from './tenant-storage-factory.js';

export function createManagementContext(
  opts: ManagementOptions,
  tenantStorage: MultiStorage<TenantState>,
  tenant: string,
): ManagementContext {
  return {
    queue: opts.queue,
    tenant: tenant,
    lock: opts.lock,
    env: opts.env,
    storageBuilder: opts.storageBuilder,
    tenantStorage,
  };
}
