import { CreateGroupEvent } from './create-group/create-group.event';
import { AcceptInviteEvent } from './accept-invite/accept-invite.event';
import { InviteUserEvent } from './invite-user/invite-user.event';
import { defineEvents } from '@aion/core/storage/define-state.js';
import { todoEvents } from '../todo/events.js';

export const groupEvents = defineEvents({
  ...todoEvents,
  'create-group': CreateGroupEvent,
  'invite-user': InviteUserEvent,
  'accept-invite': AcceptInviteEvent,
});

export type GroupEvents = typeof groupEvents;
