import { object } from '@aion/core/typing/object.js';
import { string } from '@aion/core/typing/string.js';

export const AuthRegisterRequest = object('AuthRegisterRequest', {
  tenant: string(),
  realm: string(),
  provider: string(),
  username: string(),
  password: string(),
});
