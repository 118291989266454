import { defineState } from '../storage/define-state.js';
import { declareState } from '../storage/declare-state.js';
import { string } from '../typing/string.js';
import { record } from '../typing/record.js';
import { StreamEventDeclaration } from '../realm/stream-event-declaration.js';
import { EventSource } from '../realm/event-source.js';
import { StreamState } from '../realm/stream-state.js';
import { optional } from '../typing/optional.js';
import { boolean } from '../typing/boolean.js';

export const ManagementState = defineState({
  streams: declareState(
    {
      authId: string(),
      authSub: string(),
      authIss: string(),
      authAud: string(),

      tenant: string(),
      pattern: string(),
      version: string(),
      realm: string(),

      name: string(),
      args: record(string(), string()),
      events: record(string(), StreamEventDeclaration),
      state: record(string(), StreamState),
    },
    ['tenant', 'realm', 'pattern', 'version', 'name'] as const,
  ).index('authId', [
    'tenant',
    'realm',
    'pattern',
    'version',
    'authId',
  ] as const),
  aggregations: declareState(
    {
      authId: string(),
      authSub: string(),
      authIss: string(),
      authAud: string(),

      tenant: string(),
      pattern: string(),
      version: string(),
      realm: string(),

      name: string(),
      args: record(string(), string()),
      events: record(string(), EventSource),
      state: record(string(), StreamState),
    },
    ['tenant', 'realm', 'pattern', 'version', 'name'] as const,
  ).index('authId', [
    'tenant',
    'realm',
    'pattern',
    'version',
    'authId',
  ] as const),
  permissions: declareState(
    {
      tenant: string(),
      pattern: string(),
      version: string(),
      realm: string(),
      name: string(),

      authId: string(),
      type: string(),
      read: optional(boolean()),
      write: optional(boolean()),
    },
    [
      'tenant',
      'realm',
      'pattern',
      'version',
      'name',
      'authId',
      'type',
    ] as const,
  ).index('auth', [
    'tenant',
    'realm',
    'pattern',
    'version',
    'name',
    'authId',
  ] as const),
});
