import { StateUpdate } from '../tracker/state-update.js';
import { ObservedBatches } from './observed-batches.js';
import { StateDefinition } from '../state-declaration.js';

export function appliesFilter<T extends StateDefinition<any, any, any>>(
  item: ObservedBatches<T>,
  update: StateUpdate,
): boolean {
  for (const [key, filterValue] of Object.entries(item.watch.filter)) {
    if (update.keys[key] !== filterValue) {
      return false;
    }
  }

  return true;
}
