import { TrackedStorage } from './tracker/tracked-state.js';
import { StorageClient } from './storage-client.js';
import { ObservedClient } from './observer/observed-client.js';
import { SnapshotClient } from './snapshot-client.js';
import { TransactionClient } from './storage-interface.js';
import { createMultiTransactionClient } from './create-multi-transaction-client.js';

export function createMultiTrackedStorage(
  prefix: string,
  storage: TrackedStorage<any>,
): TrackedStorage<any> {
  return {
    state<K extends string>(name: K): StorageClient<any> {
      return storage.state(`${prefix}-${name}`);
    },
    observe<K extends string>(name: K): ObservedClient<any> {
      return storage.observe(`${prefix}-${name}`);
    },
    snapshot<K extends string>(name: K): SnapshotClient<any> {
      return storage.snapshot(`${prefix}-${name}`);
    },
    transaction<T>(
      fn: (client: TransactionClient<any>) => Promise<T>,
    ): Promise<T> {
      return storage.transaction((client) => {
        return fn(createMultiTransactionClient(prefix, client));
      });
    },
  };
}
