export function getOrCreate<T>(
  map: { [key: string]: T },
  key: string,
  create: () => T,
): T {
  const existing = map[key];
  if (existing === undefined) {
    const value = create();
    map[key] = value;
    return value;
  }
  return existing;
}
