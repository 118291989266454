import { ModuleWithProviders, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AionStateService } from './aion-state.service';
import { DebugSidepanelComponent } from './debug-sidepanel/debug-sidepanel.component';
import { TableComponent } from './table/table.component';
import { TableRowComponent } from './table-row/table-row.component';
import { TableCellComponent } from './table-cell/table-cell.component';
import { HumanizeDatePipe } from './humanize-date.pipe';
import { TabGroupComponent } from './tab-group/tab-group.component';
import { TabItemComponent } from './tab-item/tab-item.component';
import { RouterModule, RouterOutlet } from '@angular/router';
import { SegmentGroupComponent } from './segement-group/segment-group.component';
import { SegmentItemComponent } from './segement-item/segment-item.component';
import { RunnerDetailComponent } from './runner-detail/runner-detail.component';
import { StateListComponent } from './state-list/state-list.component';
import { existingRunnerGuard } from './existing-runner.guard';
import { StateDetailComponent } from './state-detail/state-detail.component';

@NgModule({
  declarations: [
    DebugSidepanelComponent,
    TableComponent,
    TableRowComponent,
    TableCellComponent,
    HumanizeDatePipe,
    TabGroupComponent,
    TabItemComponent,
    SegmentGroupComponent,
    SegmentItemComponent,
    RunnerDetailComponent,
    StateListComponent,
    StateDetailComponent,
  ],
  imports: [
    CommonModule,
    RouterOutlet,
    RouterModule.forChild([
      {
        outlet: 'modal',
        component: DebugSidepanelComponent,
        path: 'debug',
        children: [
          {
            path: ':storageName',
            component: RunnerDetailComponent,
            canActivate: [existingRunnerGuard],
            children: [
              {
                path: 'states/:stateName',
                component: StateDetailComponent,
              },
              {
                path: 'states',
                component: StateListComponent,
              },
              {
                path: '',
                pathMatch: 'full',
                redirectTo: 'events',
              },
              {
                path: '**',
                redirectTo: 'events',
              },
            ],
          },
        ],
      },
    ]),
  ],
  providers: [AionStateService],
})
export class AionModule {
  static forRoot(): ModuleWithProviders<AionModule> {
    return {
      ngModule: AionModule,
      providers: [AionStateService],
    };
  }
}
