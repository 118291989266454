import { DestroyRef } from '@angular/core';
import { Observable, Subscriber } from 'rxjs';

export function signalFromDestroyRef(destroyRef: DestroyRef): AbortSignal {
  const abort = new AbortController();
  destroyRef.onDestroy(() => {
    abort.abort();
  });
  return abort.signal;
}

export function fromAsyncIterable<T>(
  asyncIterable: (abort: AbortSignal) => AsyncGenerator<T>,
) {
  return new Observable((subscriber: Subscriber<T>) => {
    const abort = new AbortController();
    const iterator = asyncIterable(abort.signal);

    process(iterator, subscriber).catch((err) => subscriber.error(err));

    return () => {
      abort.abort();
      iterator.return(null);
    };
  });
}

async function process<T>(
  asyncIterable: AsyncIterable<T> | AsyncGenerator<T>,
  subscriber: Subscriber<T>,
) {
  for await (const value of asyncIterable) {
    console.log(value);
    subscriber.next(value);
  }
  subscriber.complete();
}
