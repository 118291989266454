<div class="bg-black bg-opacity-25 flex-grow min-w-0" (click)="dismiss()"></div>
<div class="modal space-y-4 py-4 flex-shrink-0">
  <h2 class="font-bold text-xl px-8 py-4">New group</h2>

  <div
    class="flex px-8 items-start space-y-4 flex-col flex-grow min-h-0 overflow-y-auto">
    <label class="flex flex-col text-gray-600 group w-full">
      <span class="group-focus:text-emerald-600">Name</span>
      <input
        class="rounded border mt-1 py-1.5 px-2 outline-emerald-500 border-gray-200"
        [(ngModel)]="name" />
    </label>

    <button
      class="bg-emerald-600 text-white px-6 py-2 rounded"
      (click)="create()">
      Create
    </button>
  </div>
</div>
