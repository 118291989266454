<ul class="pb-6">
  <li>
    <a [routerLink]="'/personal'" [routerLinkActive]="'underline'">Personal</a>
  </li>
  @if (groups$ | async; as groups) {
    @for (group of groups.items; track group.data.id) {
      <li>
        <a [routerLink]="'/' + group.data.id" [routerLinkActive]="'underline'">
          {{ group.data.name }}
        </a>
      </li>
    }
  }
</ul>

<button class="text-emerald-700" (click)="createGroup()">Create group</button>
