import { RangeResult } from './browse-result.js';

export async function first<T>(
  iterator: AsyncGenerator<RangeResult<T>>,
): Promise<RangeResult<T> | null> {
  const result = await iterator.next();
  if (result.value) {
    return result.value;
  }
  return null;
}
