import { object } from '@aion/core/typing/object.js';
import { array } from '@aion/core/typing/array.js';
import { BrowseItem } from './browse-item.js';
import { StateValue } from '@aion/core/storage/state-value.js';
import { record } from '@aion/core/typing/record.js';
import { string } from '@aion/core/typing/string.js';
import { nullable } from '@aion/core/typing/nullable.js';

export const IndexBrowseResult = object('IndexBrowseResult', {
  items: array(BrowseItem),
  bookmark: nullable(record(string(), StateValue)),
});
