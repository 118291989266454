import { Type } from './type.js';
import { ValidateError } from './validate-error.js';

export function validate<T>(
  val: unknown,
  type: Type<T, any>,
): [ValidateError, null] | [null, T] {
  const result = type.safeParse(val, []);
  if (result.success) {
    return [null, result.data];
  } else {
    return [result.error, null];
  }
}
