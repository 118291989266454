import { IncompleteTodoEvent } from './incomplete-todo.event';
import { TodoContext } from '../context';

export async function incompleteTodo(
  evt: IncompleteTodoEvent,
  ctx: TodoContext,
) {
  await ctx.state('todos').patch({
    id: evt.id,
    completed: false,
  });
}
