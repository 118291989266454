export function parseJwt<T>(token: string, partIndex: number): T {
  const tokenPart = token.split('.')[partIndex];
  if (!tokenPart) {
    throw new Error('invalid format: token does not have enough parts');
  }

  let content = '';
  try {
    content = atob(tokenPart);
  } catch (e) {
    if (e instanceof Error) {
      throw new Error('invalid format: ' + e.message);
    } else {
      throw new Error(`invalid format: ${e}`);
    }
  }
  return JSON.parse(content) as T;
}
