<input
  type="checkbox"
  class="todo-checkbox"
  [ngClass]="{ completed: todo().completed }"
  [attr.data-todo-id]="todo().id"
  [attr.checked]="todo().completed ? 'checked' : undefined"
  (change)="updateCompletion($event)" />
<span [ngClass]="{ completed: todo().completed }" class="todo-title">
  {{ todo().title }}
</span>
