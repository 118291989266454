import { Type } from './type.js';
import { createType } from './create-type.js';
import { ValidateError } from './validate-error.js';
import { PromiseDescription } from './promise-type-description.js';
import { validate } from './validate.js';

export const promise = <TType extends Type<any, any>>(returnType: TType) =>
  createType<Promise<TType['_output']>, PromiseDescription>({
    description: {
      type: 'promise',
      returnType: returnType.description,
    },
    validate: (val, path) => {
      if (val instanceof Promise) {
        return {
          success: true,
          data: val.then((result) => {
            const [err, data] = validate(result, returnType);
            if (err) {
              throw err;
            } else {
              return data;
            }
          }),
        };
      }

      return {
        success: false,
        error: new ValidateError(
          'not a valid function at ' + path.join('->'),
          path,
          val,
        ),
      };
    },
  });
