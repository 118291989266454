<input
  class="w-full outline-0 focus:outline-0"
  #input
  (keyup)="keyUp($event, input)" />
<button class="fill-gray-600 text-2xl" (click)="create(input)">
  <svg xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 512 512">
    <path
      d="M256 16a240 240 0 1 1 0 480 240 240 0 1 1 0-480zm0 496A256 256 0 1 0 256 0a256 256 0 1 0 0 512zm-8-160c0 4.4 3.6 8 8 8s8-3.6 8-8V264h88c4.4 0 8-3.6 8-8s-3.6-8-8-8H264V160c0-4.4-3.6-8-8-8s-8 3.6-8 8v88H160c-4.4 0-8 3.6-8 8s3.6 8 8 8h88v88z" />
  </svg>
</button>
