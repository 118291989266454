export function createLazyAsyncMap<Source, Value>(
  array: Source[],
  mapper: (source: Source) => Promise<Value>,
  keySelector: (val: Value) => string,
) {
  const map: Record<string, Value> = {};
  let index = 0;

  return {
    async get(key: string): Promise<Value | null> {
      const existing = map[key];
      if (existing) {
        return existing;
      }

      for (; index < array.length; index++) {
        const item = array[index];
        if (item) {
          const mapped = await mapper(item);
          const mappedKey = keySelector(mapped);
          map[mappedKey] = mapped;
          if (key === mappedKey) {
            return mapped;
          }
        }
      }

      return null;
    },
  };
}
