<app-todo-sidebar></app-todo-sidebar>
@if (stream(); as stream) {
  <div class="flex-grow min-w-0 flex">
    <app-todo-list class="max-w-2xl mx-auto w-full px-4 mt-6 flex-grow min-h-0">
      <app-todo-list-create [stream]="stream"></app-todo-list-create>
      @if (todos$ | async; as todos) {
        @for (todo of todos.items; track todo.data.id) {
          <app-todo-list-item
            [stream]="stream"
            [id]="todo.data.id"
            [todo]="todo.data"></app-todo-list-item>
        }
      }
    </app-todo-list>
  </div>
}
