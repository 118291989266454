import { RuntimeEnv } from '@aion/core/runtime-env.js';

export function createBrowserEnv(): RuntimeEnv {
  return {
    async sha1(value: string): Promise<string> {
      const msgBuffer = new TextEncoder().encode(value);

      // hash the message
      const hashBuffer = await crypto.subtle.digest('SHA-1', msgBuffer);

      // convert ArrayBuffer to Array
      const hashArray = Array.from(new Uint8Array(hashBuffer));

      // convert bytes to hex string
      return hashArray.map((b) => b.toString(16).padStart(2, '0')).join('');
    },
    decoder(): any {
      return new TextDecoderStream();
    },
  };
}
