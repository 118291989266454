import { StreamDefinition } from '../realm/stream-definition.js';
import { StoredEvent } from '../event/stored-event.js';
import { RuntimeResult } from './runtime-result.js';
import { EventAuth } from '../event/event-auth.js';
import { EventContextStorage, EventHandleContext } from './runtime.js';
import { StreamHostRuntime } from './host-runtime.js';
import { EventRandomSeed } from '../event/event-random-seed.js';
import { getError } from './get-error.js';
import { hostResultBuilder } from './host-result-builder.js';
import { SnapshotClient } from '../storage/snapshot-client.js';
import { StorageClient } from '../storage/storage-client.js';

export function createStreamHost<
  TStream extends StreamDefinition<any, any, any, any, any>,
>(stream: TStream): StreamHostRuntime<TStream['state'], TStream['events']> {
  return {
    source: stream,
    runtime: {
      async execute(
        state: EventContextStorage<TStream['state']>,
        evt: StoredEvent,
        random: EventRandomSeed,
      ): Promise<RuntimeResult> {
        try {
          const resultBuilder = hostResultBuilder(stream, evt);

          const ctx: EventHandleContext<any, any, any> = {
            stream: {
              pattern: stream.pattern,
              allowRead(authId: EventAuth, eventTypes: string[] | string) {
                resultBuilder.addPermissionChange(
                  authId,
                  'grant_event',
                  'read',
                  eventTypes,
                );
              },
              allowReadWrite(authId: EventAuth, eventTypes: string[] | string) {
                resultBuilder.addPermissionChange(
                  authId,
                  'grant_event',
                  'readwrite',
                  eventTypes,
                );
              },
              allowWrite(authId: EventAuth, eventTypes: string[] | string) {
                resultBuilder.addPermissionChange(
                  authId,
                  'grant_event',
                  'write',
                  eventTypes,
                );
              },
              revokeRead(authId: EventAuth, eventTypes: string[] | string) {
                resultBuilder.addPermissionChange(
                  authId,
                  'revoke_event',
                  'read',
                  eventTypes,
                );
              },
              revokeReadWrite(
                authId: EventAuth,
                eventTypes: string[] | string,
              ) {
                resultBuilder.addPermissionChange(
                  authId,
                  'revoke_event',
                  'readwrite',
                  eventTypes,
                );
              },
              revokeWrite(authId: EventAuth, eventTypes: string[] | string) {
                resultBuilder.addPermissionChange(
                  authId,
                  'revoke_event',
                  'write',
                  eventTypes,
                );
              },
            },
            async emit<K extends string>(type: K, data: any): Promise<void> {
              resultBuilder.addOutput(type, data);
            },
            state<K extends string>(
              name: K,
            ): StorageClient<TStream['state'][K]> {
              return state.state(name);
            },
            snapshot<K extends string & keyof TStream['state']>(
              name: K,
            ): SnapshotClient<TStream['state'][K]> {
              return state.snapshot(name);
            },
            random,
            metadata: {
              id: evt.id,
              auth: evt.auth,
              createdAt: evt.createdAt,
              storedAt: evt.storedAt,
              annotations: evt.annotations,
            },
          };

          await stream.handle[evt.event](evt.data, ctx);

          return resultBuilder.get();
        } catch (e) {
          return {
            success: false,
            error: getError(e),
          };
        }
      },
    },
  };
}
