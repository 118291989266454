import { IDBPTransaction } from 'idb';
import { StateDefinition } from '@aion/core/storage/state-declaration.js';
import { DeleteType } from '@aion/core/storage/storage-client.js';
import { getStateKeys } from '@aion/core/runtime/get-state-data-keys.js';
import { getKeyRange } from './get-key-range.js';

export async function del<TState extends StateDefinition<any, any, any>>(
  trx: IDBPTransaction<unknown, string[], 'readwrite'>,
  storeName: string,
  state: TState,
  id: DeleteType<TState>,
): Promise<void> {
  const keys = getStateKeys(state, id);
  const store = trx.objectStore(storeName);
  await store.delete(getKeyRange(state, keys));
}
