import {
  ChangeDetectionStrategy,
  Component,
  DestroyRef,
  inject,
} from '@angular/core';
import { Router } from '@angular/router';
import { TodoStateService } from '../todo-state.service';
import { personalGroupAggregation } from '../../state/aggregation/personal-groups';
import {
  fromAsyncIterable,
  signalFromDestroyRef,
} from '../signal-from-destroy-ref.js';
import { shareReplay } from 'rxjs';

@Component({
  selector: 'app-todo-sidebar',
  templateUrl: './todo-sidebar.component.html',
  styleUrls: ['./todo-sidebar.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TodoSidebarComponent {
  router = inject(Router);
  destroyRef = inject(DestroyRef);
  state = inject(TodoStateService);

  groupAggregation = this.state.management.getAggregation(
    personalGroupAggregation,
    {
      args: {},
    },
  );

  groups$ = fromAsyncIterable((signal) =>
    this.groupAggregation.observe('groups').index('name').observeRange(null, {
      take: 10,
      dir: 'prev',
      signal,
    }),
  ).pipe(shareReplay(1));

  async createGroup() {
    await this.router.navigate([{ outlets: { modal: ['create-group'] } }], {});
  }
}
