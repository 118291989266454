import {
  StateDefinition,
  TypeOfStateDeclaration,
} from '@aion/core/storage/state-declaration.js';
import { Lazy } from '@aion/core/management/lazy.js';
import { IDBPDatabase } from 'idb';
import {
  DeleteType,
  PatchType,
  StorageClient,
  UpsertType,
} from '@aion/core/storage/storage-client.js';
import { patch } from './patch.js';
import { insert } from './insert.js';
import { CustomError } from '@aion/core/custom-error.js';
import { upsert } from './upsert.js';
import { del } from './del.js';

export function createIndexeddbStorageClient<
  T extends StateDefinition<any, any, any>,
>(
  dbPromise: Lazy<IDBPDatabase>,
  storeName: string,
  type: StateDefinition<T, any, any>,
): StorageClient<T> {
  return {
    async patch(val: PatchType<T>): Promise<void> {
      const db = await dbPromise.resolve();
      const trx = db.transaction([storeName], 'readwrite');
      await patch(trx, storeName, type, val);
      await trx.done;
    },
    async insert(val: TypeOfStateDeclaration<T>): Promise<void> {
      try {
        const db = await dbPromise.resolve();
        const trx = db.transaction([storeName], 'readwrite');
        await insert(trx, storeName, type, val);
        await trx.done;
      } catch (e) {
        throw new CustomError(`insert error`, e, { storeName });
      }
    },
    async upsert(val: UpsertType<T>): Promise<void> {
      const db = await dbPromise.resolve();
      const trx = db.transaction([storeName], 'readwrite');
      await upsert(trx, storeName, type, val);
      await trx.done;
    },
    async delete(id: DeleteType<T>): Promise<void> {
      const db = await dbPromise.resolve();
      const trx = db.transaction([storeName], 'readwrite');
      await del(trx, storeName, type, id);
      await trx.done;
    },
  };
}
