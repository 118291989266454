import { Component, inject } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-debug-sidepanel',
  templateUrl: './debug-sidepanel.component.html',
  styleUrls: ['./debug-sidepanel.component.scss'],
})
export class DebugSidepanelComponent {
  private router = inject(Router);

  dismiss() {
    this.router.navigate([{ outlets: { modal: [] } }], {});
  }
}
