export function nestedAbort(signal: AbortSignal): AbortController {
  const ctrl = new AbortController();

  signal.addEventListener(
    'abort',
    () => {
      ctrl.abort();
    },
    { once: true },
  );

  return ctrl;
}
