import { Component, HostBinding, inject, signal } from '@angular/core';
import { CellOptions, TableComponent } from '../table/table.component';

@Component({
  selector: 'app-table-row',
  templateUrl: './table-row.component.html',
  styleUrls: ['./table-row.component.scss'],
})
export class TableRowComponent {
  private table = inject(TableComponent);

  cells = signal<CellOptions[]>([]);

  @HostBinding('class')
  className = 'group';

  registerCell(name: CellOptions) {
    this.cells.update((val) => [...val, name]);
    this.table.updateCells(this.cells());
  }
}
