import { AuthDefinition } from './auth-definition.js';
import { RealmDefinition } from './realm-definition.js';

export interface RealmDefinitionOptions {
  auth: AuthDefinition;
}

export function defineRealm<TOptions extends RealmDefinitionOptions>(
  name: string,
  options: TOptions,
): Readonly<RealmDefinition<TOptions['auth']>> {
  return {
    name: name,
    auth: options.auth,
  };
}
