import { defineState } from '../storage/define-state.js';
import { declareState } from '../storage/declare-state.js';
import { string } from '../typing/string.js';
import { date } from '../typing/date.js';
import { optional } from '../typing/optional.js';
import { RuntimeResult } from '../runtime/runtime-result.js';

export const StateProcessorState = defineState({
  operations: declareState(
    {
      name: string(),
      version: string(),
      pattern: string(),
      realm: string(),
      tenant: string(),
      eventId: string(),
      result: RuntimeResult,
      appliedAt: date(),
      eventAt: date(),
    },
    ['tenant', 'realm', 'pattern', 'version', 'name', 'eventId'],
  )
    .index('eventAt', [
      'tenant',
      'realm',
      'pattern',
      'version',
      'name',
      'eventAt',
    ] as const)
    .index('appliedAt', [
      'tenant',
      'realm',
      'pattern',
      'version',
      'name',
      'appliedAt',
    ] as const),
  eventSequence: declareState(
    {
      name: string(),
      version: string(),
      pattern: string(),
      realm: string(),
      tenant: string(),
      eventId: string(),
      appliedAt: date(),
      error: optional(string()),
    },
    ['tenant', 'realm', 'pattern', 'version', 'name', 'eventId'],
  ).index('appliedAt', [
    'tenant',
    'realm',
    'pattern',
    'version',
    'name',
    'appliedAt',
  ] as const),
});

// export function createStateProcessor<TState extends StateDeclarations>(
//   options: StateProcessorOptions<TState>,
// ): StateProcessor {
//   const firstEvent = cacheOnce(async () => {
//     return await first(
//       options.storage
//         .storage('processor')
//         .snapshot('eventSequence')
//         .index('appliedAt')
//         .filter('streamId', options.streamId)
//         .range(null),
//     );
//   });
//
//   return {
//     async until(eventId: string) {
//       const abortController = new AbortController();
//       const iterator = options.storage
//         .storage('processor')
//         .observe('eventSequence')
//         .iterate(eventId, { continuous: abortController.signal });
//       await iterator.next();
//       abortController.abort();
//     },
//     async process(abort: AbortSignal) {
//       // TODO lock, only one processor at a time
//       const lastEvent = await first(
//         options.storage
//           .storage('processor')
//           .snapshot('eventSequence')
//           .index('appliedAt')
//           .filter('streamId', options.streamId)
//           .range(null, { dir: 'prev' }),
//       );
//       const bookmark = lastEvent?.data?.appliedAt ?? null;
//
//       for await (const evt of options.storage
//         .storage('processor')
//         .observe('operations')
//         .index('appliedAt')
//         .filter('streamId', options.streamId)
//         .iterate(bookmark, {
//           continuous: abort,
//           open: true,
//         })) {
//         const result = evt.data.result;
//         if (!result.success) {
//           await options.storage
//             .storage('processor')
//             .state('eventSequence')
//             .insert(evt.id, {
//               streamId: options.streamId,
//               eventId: evt.id,
//               appliedAt: evt.data.appliedAt,
//               error: result.error,
//             });
//         } else {
//           const authId = await getAuthId(result.auth, options.env);
//           if (await firstEvent.isEmpty()) {
//             await options.storage
//               .storage('processor')
//               .transaction(async (trx) => {
//                 for (const event of options.eventKeys) {
//                   await trx
//                     .state('permissions')
//                     .insert(`${authId}:${options.streamId}:${event}`, {
//                       streamId: options.streamId,
//                       authId,
//                       eventId: event,
//                       write: true,
//                       read: true,
//                     });
//                 }
//               });
//           } else {
//             const currentPermission = await options.permissionCache.resolve(
//               authId,
//               result.event,
//             );
//
//             if (!currentPermission.write) {
//               await options.storage
//                 .storage('processor')
//                 .state('eventSequence')
//                 .insert(evt.id, {
//                   streamId: options.streamId,
//                   eventId: evt.id,
//                   appliedAt: evt.data.appliedAt,
//                   error: 'no permission',
//                 });
//               continue;
//             }
//           }
//
//           try {
//             await options.storage.transaction(async (trx) => {
//               await trx
//                 .storage('processor')
//                 .state('eventSequence')
//                 .insert(evt.id, {
//                   streamId: options.streamId,
//                   eventId: evt.id,
//                   appliedAt: evt.data.appliedAt,
//                   error: undefined,
//                 });
//
//               const state = createPartitionedTransactionClient<TState>(
//                 options.partitionName,
//                 options.streamId,
//                 trx.storage('state'),
//               );
//               for (const operation of result.operation) {
//                 if (operation.type === 'delete') {
//                   await state.state(operation.state).delete(operation.id);
//                 } else if (operation.type === 'upsert') {
//                   await state
//                     .state(operation.state)
//                     .upsert(operation.id, operation.data as any);
//                 } else if (operation.type === 'insert') {
//                   await state
//                     .state(operation.state)
//                     .insert(operation.id, operation.data as any);
//                 } else if (operation.type === 'patch') {
//                   await state
//                     .state(operation.state)
//                     .patch(operation.id, operation.data as any);
//                 } else {
//                   failNever(operation, 'Unknown operation type');
//                 }
//               }
//
//               if (result.permissions) {
//                 for (const permission of result.permissions) {
//                   const permissionAuthId = await getAuthId(
//                     permission.auth,
//                     options.env,
//                   );
//                   const value = permission.type === 'grant_event';
//                   if (permission.permission === 'write') {
//                     await trx
//                       .storage('processor')
//                       .state('permissions')
//                       .upsert(`${permissionAuthId}:${permission.event}`, {
//                         streamId: options.streamId,
//                         authId: permissionAuthId,
//                         eventId: permission.event,
//                         write: value,
//                         read: undefined,
//                       });
//                   } else if (permission.permission === 'read') {
//                     await trx
//                       .storage('processor')
//                       .state('permissions')
//                       .upsert(`${permissionAuthId}:${permission.event}`, {
//                         streamId: options.streamId,
//                         authId: permissionAuthId,
//                         eventId: permission.event,
//                         read: value,
//                         write: undefined,
//                       });
//                   } else {
//                     await trx
//                       .storage('processor')
//                       .state('permissions')
//                       .upsert(`${permissionAuthId}:${permission.event}`, {
//                         streamId: options.streamId,
//                         authId: permissionAuthId,
//                         eventId: permission.event,
//                         write: value,
//                         read: value,
//                       });
//                   }
//                 }
//               }
//             });
//           } catch (e) {
//             await options.storage
//               .storage('processor')
//               .state('eventSequence')
//               .insert(evt.id, {
//                 streamId: options.streamId,
//                 eventId: evt.id,
//                 appliedAt: evt.data.appliedAt,
//                 error: getError(e),
//               });
//           }
//         }
//       }
//     },
//   };
// }
