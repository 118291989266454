import { HttpMiddleware } from './http-middleware.js';
import { HttpHandler } from './http-handler.js';
import { HttpRequest } from './http-request.js';
import { HttpResponse } from './http-response.js';
import { HttpMethod } from '../../client/http-method.js';
import { CustomError } from '../../../custom-error.js';
import { getOrCreate } from '../../../utils/get-or-create.js';
import { failNever } from '../../../utils/fail-never.js';

export type PathSegment =
  | { path: string; type: 'path' }
  | { param: string; type: 'param' };

export interface HttpTreeNode<
  Req extends HttpRequest,
  Res extends HttpResponse,
  Params,
> {
  readonly _req: Req;
  readonly _res: Res;
  readonly _params: Params;

  readonly routing: RoutingNode;
  readonly segments: PathSegment[];
  readonly middlewares: HttpMiddleware<any, any, any, any>[];
  readonly extras: Record<string, unknown>;
}

export interface HttpLeafNode<
  Req extends HttpRequest,
  Res extends HttpResponse,
  Params,
> {
  readonly _req: Req;
  readonly _res: Res;
  readonly _params: Params;

  readonly method: HttpMethod;
  readonly routing: RoutingNode;
  readonly segments: PathSegment[];
  readonly middlewares: HttpMiddleware<any, any, any, any>[];
  readonly extras: Record<string, unknown>;
}

export function newRootNode(): HttpTreeNode<HttpRequest, HttpResponse, {}> {
  return {
    _req: undefined as any,
    _res: undefined as any,
    _params: undefined as any,
    routing: {
      paramNode: null,
      methods: {},
      pathNodes: {},
      segments: [],
    },
    segments: [],
    middlewares: [],
    extras: {},
  };
}

export function parsePath(
  node: HttpTreeNode<HttpRequest, HttpResponse, any>,
  path: string,
): HttpTreeNode<HttpRequest, HttpResponse, any> {
  const parts = path.split('/').filter((s) => !!s);

  let current = node;
  for (const part of parts) {
    if (part.startsWith('{') && part.endsWith('}')) {
      current = paramNode(current, part.substring(1, part.length - 1));
    } else {
      current = pathNode(current, part);
    }
  }
  return current;
}

export function stringifyPath<Params>(
  node: HttpTreeNode<HttpRequest, HttpResponse, Params>,
  params: Params,
): string {
  let path = '';
  for (const segment of node.segments) {
    if (segment.type === 'path') {
      path += path.length === 0 ? segment.path : '/' + segment.path;
    } else {
      path +=
        path.length === 0
          ? (params as any)[segment.param]
          : '/' + (params as any)[segment.param];
    }
  }
  return path;
}

export interface RoutingNode {
  pathNodes: Record<string, RoutingNode>;
  paramNode: RoutingNode | null;
  readonly segments: PathSegment[];
  readonly methods: Record<string, RoutingLeafNode>;
}

export interface RoutingLeafNode {
  readonly method: HttpMethod;
  readonly segments: PathSegment[];
  readonly handler: HttpHandler<any, any, any>;
  readonly extras: Record<string, unknown>;
}

export function createRouter<
  Request extends HttpRequest,
  Response extends HttpResponse,
>(node: HttpTreeNode<HttpRequest, HttpResponse, {}>) {
  return {
    async handle(req: Request, res: Response): Promise<Response> {
      let currentNode = node.routing;
      const currentParams: string[] = [];

      for (const nextPath of req.paths) {
        const childNode = currentNode.pathNodes[nextPath];
        if (childNode) {
          currentNode = childNode;
        } else if (currentNode.paramNode) {
          currentParams.push(nextPath);
          currentNode = currentNode.paramNode;
        } else {
          return res.statusCode(404);
        }
      }

      const handler = currentNode.methods[req.method];
      if (!handler) {
        return res.statusCode(404);
      }

      return handler.handler(req, res, currentParams);
    },
  };
}

export function useMiddleware<
  Req extends HttpRequest,
  Res extends HttpResponse,
  Params,
  NewReq extends HttpRequest,
  NewRes extends HttpResponse,
>(
  node: HttpTreeNode<Req, Res, Params>,
  middleware: HttpMiddleware<Req, Res, NewReq, NewRes>,
): HttpTreeNode<NewReq, NewRes, Params> {
  if (node.middlewares.indexOf(middleware) >= 0) {
    return node as any;
  }

  return {
    ...node,
    middlewares: [middleware, ...node.middlewares],
  } as any;
}

export function useLeafMiddleware<
  Req extends HttpRequest,
  Res extends HttpResponse,
  Params,
  NewReq extends HttpRequest,
  NewRes extends HttpResponse,
>(
  node: HttpLeafNode<Req, Res, Params>,
  middleware: HttpMiddleware<Req, Res, NewReq, NewRes>,
): HttpLeafNode<NewReq, NewRes, Params> {
  if (node.middlewares.indexOf(middleware) >= 0) {
    return node as any;
  }

  return {
    ...node,
    middlewares: [middleware, ...node.middlewares],
  } as any;
}

export function paramNode<Node extends HttpTreeNode<any, any, any>>(
  node: Node,
  name: string,
): Node {
  return {
    ...node,
    parent: node,
    segments: [
      ...node.segments,
      {
        param: name.toString(),
        type: 'param',
      },
    ],
  };
}

function getPathSegment(path: string) {
  const paths: PathSegment[] = path
    .split('/')
    .filter((s) => !!s)
    .map((s) => ({ path: s, type: 'path' }));
  return paths;
}

export function pathNode<
  Request extends HttpRequest,
  Response extends HttpResponse,
  Params,
>(
  node: HttpTreeNode<Request, Response, Params>,
  path: string,
): HttpTreeNode<Request, Response, Params> {
  const segments = getPathSegment(path);

  return {
    ...node,
    segments: [...node.segments, ...segments],
  };
}

export function nodeMethod<
  Req extends HttpRequest,
  Res extends HttpResponse,
  Params,
>(
  method: HttpMethod,
  node: HttpTreeNode<Req, Res, Params>,
): HttpLeafNode<Req, Res, Params> {
  return {
    ...node,
    method,
  };
}

function pathDescription(segments: PathSegment[]): string {
  return segments
    .map((s) => (s.type === 'param' ? `:${s.param}` : s.path))
    .join('/');
}

export function addHandler<
  Req extends HttpRequest,
  Res extends HttpResponse,
  Params,
>(
  node: HttpLeafNode<Req, Res, Params>,
  handler: HttpHandler<Req, Res, Params>,
) {
  const description = pathDescription(node.segments);
  console.log(`register handler on [${node.method}] ${description}`);

  let routingNode = node.routing;
  const currentSegments = [...routingNode.segments];
  for (const segment of node.segments) {
    currentSegments.push(segment);
    if (segment.type === 'path') {
      if (routingNode.paramNode) {
        throw new CustomError(
          'cant use path and param node on same level',
          null,
          {
            path: pathDescription(currentSegments),
          },
        );
      }
      routingNode = getOrCreate(routingNode.pathNodes, segment.path, () => ({
        pathNodes: {},
        paramNode: null,
        methods: {},
        segments: [...currentSegments],
      }));
    } else if (segment.type === 'param') {
      if (routingNode.paramNode) {
        routingNode = routingNode.paramNode;
      } else {
        if (Object.keys(routingNode.pathNodes).length > 0) {
          throw new CustomError(
            'cant use path and param node on same level',
            null,
            {
              path: pathDescription(currentSegments),
            },
          );
        }

        const newNode: RoutingNode = {
          pathNodes: {},
          paramNode: null,
          methods: {},
          segments: [...currentSegments],
        };
        routingNode.paramNode = newNode;
        routingNode = newNode;
      }
    } else {
      failNever(segment, 'unknown segment');
    }
  }

  if (routingNode.methods[node.method]) {
    throw new CustomError('handler already registered on route', null, {
      method: node.method,
      path: pathDescription,
    });
  }

  let routingHandler: HttpHandler<Req, Res, Params> = handler;
  if (node.middlewares.length > 0) {
    for (const middleware of node.middlewares) {
      routingHandler = createMiddlewareHandler(middleware, routingHandler);
    }
  }

  routingNode.methods[node.method] = {
    handler: routingHandler,
    method: node.method,
    extras: node.extras,
    segments: currentSegments,
  };
}

function createMiddlewareHandler(
  middleware: HttpMiddleware<any, any, any, any>,
  nextHandler: HttpHandler<any, any, any>,
): HttpHandler<any, any, any> {
  return (req, res, params) => {
    return middleware({
      req,
      res,
      next: async (newReq, newRes) => nextHandler(newReq, newRes, params),
    });
  };
}
