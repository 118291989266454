import { RangeOptions } from '@aion/core/storage/snapshot-index-client.js';
import { BrowseResult, RangeResult } from '@aion/core/storage/browse-result.js';
import { StateValue } from '@aion/core/storage/state-value.js';
import { mapToState } from '@aion/core/runtime/get-state-data-keys.js';
import { StateDefinition } from '@aion/core/storage/state-declaration.js';

export async function* iterateResponse<
  TState extends StateDefinition<any, any, any>,
>(
  state: TState,
  bookmark: Record<string, StateValue> | null,
  opts: Partial<RangeOptions> | undefined,
  req: (
    bookmark: Record<string, StateValue> | null,
    opts: RangeOptions,
  ) => Promise<{ result: BrowseResult<Record<string, StateValue>> }>,
): AsyncGenerator<RangeResult<TState>> {
  const options = {
    dir: opts?.dir ?? 'next',
    open: opts?.open ?? false,
    continuous: false, // TODO implement continuous
  };

  let currentBookmark = bookmark;
  do {
    const response = await req(currentBookmark, options);

    for (const item of response.result.items) {
      yield {
        data: mapToState(state, item.data),
        keys: item.keys,
      };
    }
    currentBookmark = response.result.bookmark;
    if (response.result.items.length < 10) {
      return;
    }
  } while (true);
}
