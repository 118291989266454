export interface Runnable<T> {
  ready: Promise<Running<T>>;
}

export interface Running<T> {
  value: T;
  done: Promise<void>;
}

export function combineRunnable(runnables: Runnable<any>[]): Runnable<void> {
  return {
    ready: Promise.all(runnables.map((r) => r.ready)).then((runnings) => {
      return {
        value: undefined,
        done: Promise.all(runnings.map((r) => r.done)).then(),
      };
    }),
  };
}

export function runnableFromFunction<T>(
  promise: () => Promise<Running<T>>,
): Runnable<T> {
  return {
    ready: promise().then((r) => r),
  };
}
export function runnableAsyncRunnable<T>(
  promise: Promise<Runnable<T>>,
): Runnable<T> {
  return {
    ready: promise.then((r) => r.ready),
  };
}

export function runnableFromPromise<T>(promise: Promise<T>): Runnable<T> {
  return {
    ready: promise.then((value) => ({
      value,
      done: Promise.resolve(),
    })),
  };
}
