import { object } from '../typing/object.js';
import { literal } from '../typing/literal.js';
import { union } from '../typing/union.js';
import { string } from '../typing/string.js';
import { TypeOf } from '../typing/type-of.js';

export const JwtStoreAlgorithm = union('JwtStoreAlgorithm', [literal('HS256')]);

export const JwtStore = object('JwtStore', {
  type: literal('jwt'),
  algorithm: JwtStoreAlgorithm,
  secret: string(),
  issuer: string(),
  audience: string(),
  expirationTime: string(),
});
export type JwtStore = TypeOf<typeof JwtStore>;

export interface JwtStoreOptions {
  algorithm?: 'HS256';
  secret: string;
  issuer: string;
  audience: string;
  expirationTime?: string;
}

export function jwtStore(options: JwtStoreOptions): JwtStore {
  return {
    type: 'jwt',
    algorithm: options.algorithm ?? 'HS256',
    audience: options.audience,
    issuer: options.issuer,
    secret: options.secret,
    expirationTime: options.expirationTime ?? '10min',
  };
}
