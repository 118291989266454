import { Type } from '../typing/type.js';
import {
  IndexOptions,
  StateDeclaration,
  StateIndex,
  StateIndexKeys,
  StateIndexValue,
  StateIndices,
} from './state-declaration.js';
import { object } from '../typing/object.js';
import { ObjectTypeDescription } from '../typing/object-type-description.js';
import { TypeDescription } from '../typing/type-description.js';

export function declareState<
  T extends {
    [key: string]: Type<any, any>;
  },
  Key extends keyof StateIndexValue<{
    [Prop in keyof T & string]: T[Prop]['_output'];
  }>,
>(
  type: T,
  key: Key[],
): StateDeclaration<
  { [Prop in keyof T & string]: T[Prop]['_output'] },
  Key,
  Record<string, never>
> {
  return stateIndexBuilder(object('state', type), key, {});
}
export function declareStateFromObject<
  Output,
  Key extends keyof StateIndexValue<Output>,
  Desc extends TypeDescription,
>(type: Type<Output, Desc>, key: Key[]): StateDeclaration<Output, Key, {}> {
  return stateIndexBuilder(type, key, {});
}

function stateIndexBuilder<
  T,
  Key extends keyof StateIndexValue<T>,
  I extends StateIndices<any>,
>(type: Type<T, any>, key: Key[], indices: I): StateDeclaration<T, Key, I> {
  return {
    type,
    indices,
    key,
    index<K extends string, IK extends StateIndexKeys<T>[]>(
      name: K,
      fields: IK,
      options: IndexOptions,
    ): StateDeclaration<T, Key, I & Record<K, StateIndex<T, IK>>> {
      return stateIndexBuilder<T, Key, I & Record<K, StateIndex<T, IK>>>(
        type,
        key,
        {
          ...indices,
          ...{
            [name]: {
              fields,
              unique: options?.unique ?? false,
            },
          },
        },
      );
    },
  };
}

export function declareEvent<
  T extends {
    [key: string]: Type<any, any>;
  },
>(
  type: T,
): Type<
  { [Prop in keyof T & string]: T[Prop]['_output'] },
  ObjectTypeDescription
> {
  return object('event', type);
}
