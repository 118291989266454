import { Component, inject, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-segment-item',
  templateUrl: './segment-item.component.html',
  styleUrls: ['./segment-item.component.scss'],
})
export class SegmentItemComponent {
  @Input()
  link!: string | string[];
}
