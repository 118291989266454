import { Type } from '../typing/type.js';

export interface TopicOptions {
  attributes: string[];
  data: Type<any, any>;
}

export interface TopicDefinition<
  Attributes extends string[],
  Data extends Type<any, any>,
> {
  name: string;
  attributes: Attributes;
  data: Data;
}

export function defineTopic<T extends TopicOptions>(
  name: string,
  opts: T,
): Readonly<TopicDefinition<T['attributes'], T['data']>> {
  return {
    name,
    data: opts.data,
    attributes: opts.attributes,
  };
}
