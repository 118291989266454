import { createType } from './create-type.js';
import { ValidateError } from './validate-error.js';
import { FuncDescription } from './function-description.js';
import { Type } from './type.js';
import { validate } from './validate.js';

export const func = <
  TArguments extends Type<any, any>[],
  TReturnType extends Type<any, any>,
>(
  argsType: TArguments,
  returnType: TReturnType,
) =>
  createType<
    (...args: ArgumentsOutput<TArguments>) => ValueOfType<TReturnType>,
    FuncDescription
  >({
    description: {
      type: 'func',
      returnType: returnType.description,
      argumentTypes: argsType.map((a) => a.description),
    },
    validate: (val, path) => {
      if (typeof val === 'function') {
        return {
          success: true,
          data: (
            ...args: ArgumentsOutput<TArguments>
          ): ValueOfType<TReturnType> => {
            const result = val(...args);
            const [err, data] = validate(result, returnType);
            if (err) {
              throw err;
            }
            return data;
          },
        };
      }

      return {
        success: false,
        error: new ValidateError(
          'not a valid function at ' + path.join('->'),
          path,
          val,
        ),
      };
    },
  });

export type ValueOfType<T extends Type<any, any>> =
  T extends Type<infer Value, any> ? Value : never;

type ArgumentsOutput<TArguments extends Type<any, any>[]> = {
  [K in keyof TArguments]: TArguments[K]['_output'];
};
