import { EventRecord } from './event-record.js';
import { declareStateFromObject } from '../storage/declare-state.js';
import { defineState } from '../storage/define-state.js';

export const EventState = defineState({
  events: declareStateFromObject(EventRecord, ['id'])
    .index('storedAt', ['tenant', 'realm', 'pattern', 'storedAt'] as const)
    .index('id', ['tenant', 'realm', 'pattern', 'id'] as const)
    .index('authId', [
      'tenant',
      'realm',
      'pattern',
      'authId',
      'storedAt',
    ] as const),
});
