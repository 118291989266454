import { object } from '../typing/object.js';
import { literal } from '../typing/literal.js';
import { string } from '../typing/string.js';
import { TypeOf } from '../typing/type-of.js';

export const Secret = object('Secret', {
  type: literal('secret'),
  name: string(),
});

export const SecretSchema = {
  type: 'object',
  properties: {
    type: {
      const: 'secret',
    },
    name: {
      type: 'string',
    },
  } as const,
  required: ['type', 'name'],
} as const;

export type Secret = TypeOf<typeof Secret>;

export function secret(name: string): Secret {
  return {
    type: 'secret',
    name,
  };
}
