import { Type } from './type.js';
import { lazyType } from './lazy-type.js';
import { union } from './union.js';
import { object } from './object.js';
import { literal } from './literal.js';
import { array } from './array.js';
import { string } from './string.js';
import { record } from './record.js';
import type { TypeDescription } from './type-description.js';
import { number } from './number.js';
import { boolean } from './boolean.js';

export const TypeDescriptionSchema: Type<TypeDescription, TypeDescription> =
  lazyType<Type<TypeDescription, TypeDescription>>(
    () =>
      union('TypeDescription', [
        LiteralTypeDescription,
        RecordTypeDescriptionSchema,
        OptionalTypeDescriptionSchema,
        ObjectTypeDescription,
        UnionTypeDescriptionSchema,
        ArrayTypeDescription,
        StringTypeDescriptionSchema,
        DateTypeDescription,
        NumberAsTextTypeDescription,
        NumberTypeDescription,
        NullableTypeDescription,
        BooleanTypeDescription,
        FuncDescription,
        PromiseDescriptionSchema,
      ]) as any,
  );

export const PromiseDescriptionSchema = object('PromiseDescription', {
  type: literal('promise'),
  returnType: TypeDescriptionSchema,
});

export const UnionTypeDescriptionSchema = object('UnionTypeDescription', {
  type: literal('union'),
  unionTypes: array(TypeDescriptionSchema),
  name: string(),
});
export const NumberTypeDescription = object('NumberTypeDescription', {
  type: literal('number'),
});

export const ArrayTypeDescription = object('ArrayTypeDescription', {
  type: literal('array'),
  itemType: TypeDescriptionSchema,
});

export const NumberAsTextTypeDescription = object(
  'NumberAsTextTypeDescription',
  {
    type: literal('numberAsText'),
  },
);

export const DateTypeDescription = object('DateTypeDescription', {
  type: literal('date'),
});

export const BooleanTypeDescription = object('BooleanTypeDescription', {
  type: literal('boolean'),
});

export const StringTypeDescriptionSchema = object('StringTypeDescription', {
  type: literal('string'),
});

export const LiteralTypeDescription = object('LiteralTypeDescription', {
  type: literal('literal'),
  constant: union('StringOrNumberOrBoolean', [string(), number(), boolean()]),
});

export const FuncDescription = object('FuncDescription', {
  type: literal('func'),
  argumentTypes: array(TypeDescriptionSchema),
  returnType: TypeDescriptionSchema,
});

export const NullableTypeDescription = object('NullableTypeDescription', {
  type: literal('nullable'),
  nullableType: TypeDescriptionSchema,
});

export const RecordTypeDescriptionSchema = object('RecordTypeDescription', {
  type: literal('record'),
  keyType: union('StringOrNumber', [
    StringTypeDescriptionSchema,
    NumberTypeDescription,
  ]),
  valueType: TypeDescriptionSchema,
});

export const OptionalTypeDescriptionSchema = object('OptionalTypeDescription', {
  type: literal('optional'),
  optionalType: TypeDescriptionSchema,
});

export const ObjectTypeDescription = object('ObjectTypeDescription', {
  type: literal('object'),
  object: record(string(), TypeDescriptionSchema),
  name: string(),
});
