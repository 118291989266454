import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { TodoListComponent } from './todo-list/todo-list.component';
import { TodoListItemComponent } from './todo-list-item/todo-list-item.component';
import { TodoListCreateComponent } from './todo-list-create/todo-list-create.component';
import { AionModule } from './aion/aion.module';
import { NavbarComponent } from './navbar/navbar.component';
import { TodoSidebarComponent } from './todo-sidebar/todo-sidebar.component';
import { TodoPageComponent } from './todo-page/todo-page.component';
import { GroupCreateModalComponent } from './group-create-modal/group-create-modal.component';
import { LoginComponent } from './login/login.component';
import { FormsModule } from '@angular/forms';

@NgModule({
  declarations: [
    AppComponent,
    TodoListComponent,
    TodoListItemComponent,
    TodoListCreateComponent,
    NavbarComponent,
    TodoSidebarComponent,
    TodoPageComponent,
    GroupCreateModalComponent,
    LoginComponent,
  ],
  imports: [BrowserModule, AppRoutingModule, AionModule.forRoot(), FormsModule],
  bootstrap: [AppComponent],
})
export class AppModule {}
