import { union } from '../typing/union.js';
import { string } from '../typing/string.js';
import { number } from '../typing/number.js';
import { date } from '../typing/date.js';
import { boolean } from '../typing/boolean.js';
import { TypeOf } from '../typing/type-of.js';

export const StateValue = union('StateValue', [
  string(),
  number(),
  date(),
  boolean(),
]);

export type StateValue = TypeOf<typeof StateValue>;

export const isStateValue = (val: unknown): val is StateValue =>
  typeof val === 'string' ||
  typeof val === 'number' ||
  typeof val === 'boolean' ||
  (typeof val === 'object' && val !== null && val.constructor.name === 'Date');
