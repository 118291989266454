import { JwtSessionData } from './jwt-session-data.js';
import { nowInSeconds } from './now-in-seconds.js';
import { RealmDefinition } from '@aion/core/realm/realm-definition.js';

export function buildJwtSessionData(
  realm: RealmDefinition<any>,
  tenant: string,
  provider: string,
  result: {
    id_token: string;
    refresh_token: string;
    expires_in: number;
    username: string;
  },
): JwtSessionData {
  return {
    tenant: tenant,
    realm: realm.name,
    provider: provider,
    exp: nowInSeconds() + result.expires_in,
    accessToken: result.id_token,
    refreshToken: result.refresh_token,
    username: result.username,
  };
}
