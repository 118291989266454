import {
  AggregateHostRuntime,
  HostRuntimeExecution,
} from '../runtime/host-runtime.js';

export function mapAggregationRuntimes(
  aggregationInstance: AggregateHostRuntime<any>,
): Record<string, HostRuntimeExecution<any>> {
  return Object.entries(aggregationInstance.runtimes).reduce<
    Record<string, HostRuntimeExecution<any>>
  >((map, [key, stream]) => {
    map[key] = stream.runtime;
    return map;
  }, {});
}
