import { EventAuth } from './event-auth.js';
import { object } from '../typing/object.js';
import { string } from '../typing/string.js';
import { date } from '../typing/date.js';
import { record } from '../typing/record.js';
import { TypeOf } from '../typing/type-of.js';
import { StateValue } from '../storage/state-value.js';

export const StoredEvent = object('StoredEvent', {
  id: string(),
  event: string(),
  data: record(string(), StateValue),
  storedAt: date(),
  createdAt: date(),
  auth: EventAuth,
  annotations: record(string(), string()),
  version: string(),
  pattern: string(),
  authId: string(),
  tenant: string(),
  realm: string(),
});

export type StoredEvent = TypeOf<typeof StoredEvent>;
