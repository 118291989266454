import { OpenIDProvider } from './open-id-provider.js';
import { Secret } from './secret.js';

export interface MicrosoftOAuthOptions {
  clientId: string;
  clientSecret: Secret;
  tenant: 'common' | 'organizations' | 'consumers' | string;
  scope?: string;
}

export function createMicrosoftOAuth(
  opts: MicrosoftOAuthOptions,
): Readonly<OpenIDProvider> {
  return {
    type: 'openid',
    clientId: opts.clientId,
    clientSecret: opts.clientSecret,

    audience: undefined,
    issuer:
      'https://login.microsoftonline.com/9188040d-6c67-4c5b-b112-36a304b66dad/v2.0',

    //openIdConfigurationUrl: 'https://login.microsoftonline.com/common/v2.0/.well-known/openid-configuration',
    jwks: {
      url: 'https://login.microsoftonline.com/common/discovery/v2.0/keys',
    },

    token: {
      url: 'https://login.microsoftonline.com/common/oauth2/v2.0/token',
      formData: {},
    },

    authorize: {
      url: 'https://login.microsoftonline.com/common/oauth2/v2.0/authorize',
      queryParams: {
        client_id: opts.clientId,
        tenant: opts.tenant,
        scope: opts.scope ?? 'openid email offline_access profile',
        response_type: 'code',
      },
    },
  };
}
