import {
  StateDefinition,
  TypeOfStateDeclaration,
} from '../storage/state-declaration.js';
import { PatchType, UpsertType } from '../storage/storage-client.js';
import { isStateValue, StateValue } from '../storage/state-value.js';
import { CustomError } from '../custom-error.js';
import { TypeDescription } from '../typing/type-description.js';
import { ObjectTypeDescription } from '../typing/object-type-description.js';

export function getStateDataKeys<TState extends StateDefinition<any, any, any>>(
  state: TState,
  val: UpsertType<TState> | PatchType<TState> | TypeOfStateDeclaration<TState>,
): {
  data: Record<string, StateValue>;
  keys: Record<string, StateValue>;
} {
  return {
    data: getStateData(state.type.description, val),
    keys: getStateKeys(state, val),
  };
}

export function getStateData<TState extends StateDefinition<any, any, any>>(
  description: ObjectTypeDescription,
  val: UpsertType<TState> | PatchType<TState> | TypeOfStateDeclaration<TState>,
): Record<string, StateValue> {
  const data: Record<string, StateValue> = {};

  iterateData(description, data, '', val);

  return data;
}

function iterateData<TState extends StateDefinition<any, any, any>>(
  type: TypeDescription,
  data: Record<string, StateValue>,
  prefix: string,
  val: UpsertType<TState> | PatchType<TState> | TypeOfStateDeclaration<TState>,
): void {
  if (
    type.type === 'string' ||
    type.type === 'number' ||
    type.type === 'boolean' ||
    type.type === 'date'
  ) {
    if (isStateValue(val)) {
      data[`${prefix}.`] = val;
    } else {
      // TODO merge
    }
  } else if (type.type === 'array') {
    if (val instanceof Array) {
      const items = Object.values(val);
      for (let i = 0; i < items.length; i++) {
        iterateData(type.itemType, data, `${prefix}.${i}`, items[i]);
      }
    }
  } else if (type.type === 'object') {
    for (const [key, value] of Object.entries(val)) {
      const propType = type.object[key];
      if (propType) {
        iterateData(
          propType,
          data,
          prefix.length > 0 ? `${prefix}.${key}` : key,
          value as any,
        );
      } else {
        // TODO
      }
    }
  }
}

export function getStateKeys<TState extends StateDefinition<any, any, any>>(
  state: TState,
  val: UpsertType<TState> | PatchType<TState> | TypeOfStateDeclaration<TState>,
): Record<string, StateValue> {
  const keys: Record<string, StateValue> = {};

  for (const key of state.key) {
    const value = key in val ? (val as any)[key] : null;
    if (isStateValue(value)) {
      keys[key] = value;
    } else {
      throw new CustomError('invalid key value', null, { keys });
    }
  }

  return keys;
}

export function mapToState<TState extends StateDefinition<any, any, any>>(
  state: TState,
  data: Record<string, StateValue>,
): TypeOfStateDeclaration<TState> {
  return iterateMap(state.type.description, '', { ...data });
}

function getValuesWithPrefix(
  prefix: string,
  value: Record<string, StateValue>,
): Record<string, StateValue> {
  return Object.entries(value).reduce<Record<string, StateValue>>(
    (map, [key, value]) => {
      if (key.startsWith(prefix)) {
        map[key.substring(prefix.length)] = value;
      }
      return map;
    },
    {},
  );
}

function iterateMap(
  type: TypeDescription,
  prefix: string,
  value: Record<string, StateValue>,
): any {
  if (
    type.type === 'string' ||
    type.type === 'number' ||
    type.type === 'boolean' ||
    type.type === 'date'
  ) {
    return value[''];
  } else if (type.type === 'array') {
    const result: any[] = [];
    let i = 0;
    let indexValue: Record<string, StateValue> = {};

    do {
      indexValue = getValuesWithPrefix(`${i}.`, value);
      if (Object.keys(indexValue).length > 0) {
        result.push(iterateMap(type.itemType, `${i}.`, indexValue));
      } else {
        break;
      }
      i++;
    } while (Object.keys(indexValue).length > 0);
    return result;
  } else if (type.type === 'object') {
    const result: any = {};

    for (const [key, propType] of Object.entries(type.object)) {
      const propValues = getValuesWithPrefix(`${key}.`, value);
      if (Object.keys(propValues).length > 0) {
        result[key] = iterateMap(propType, key, propValues);
      }
    }

    return result;
  }
}
