import { CustomError } from '../custom-error.js';

export function getOrFail<T>(map: { [key: string]: T }, key: string): T {
  const value = map[key];
  if (!value) {
    throw new CustomError('missing key', null, {
      key,
    });
  }
  return value;
}

export async function getOrFailAsync<T>(
  map: { get(key: string): Promise<T | null> },
  key: string,
): Promise<T> {
  const value = await map.get(key);
  if (!value) {
    throw new CustomError('missing key', null, {
      key,
    });
  }
  return value;
}
