import { EventSource } from '../realm/event-source.js';
import { RuntimeEnv } from '../runtime-env.js';

export async function mapAggregationEventSource(
  events: Record<
    string,
    {
      event: string;
      stream: {
        pattern: string;
        version: { resolve: (env: RuntimeEnv) => Promise<string> };
      };
    }
  >,
  env: RuntimeEnv,
): Promise<Record<string, EventSource>> {
  const result: Record<string, EventSource> = {};
  for (const [key, evt] of Object.entries(events)) {
    result[key] = {
      event: evt.event,
      stream: {
        pattern: evt.stream.pattern,
        version: await evt.stream.version.resolve(env),
      },
    };
  }
  return result;
}
