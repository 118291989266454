import { CanActivateFn, Router } from '@angular/router';
import { inject } from '@angular/core';
import { TodoStateService } from './todo-state.service';

export const loginGuard: CanActivateFn = async (route, state) => {
  const todoState = inject(TodoStateService);
  const router = inject(Router);
  const auth = await todoState.management.session.resolveAuth();
  if (auth.type === 'unauthenticated') {
    return router.createUrlTree(['/login']);
  } else {
    return true;
  }
};
