import { Type } from './type.js';
import { createType } from './create-type.js';
import { ObjectTypeDescription } from './object-type-description.js';
import { ValidateError } from './validate-error.js';
import { TypeDescription } from './type-description.js';

export const object = <
  T extends {
    [key: string]: Type<any, any>;
  },
>(
  name: string,
  value: T,
) =>
  createType<
    { [Prop in keyof T & string]: T[Prop]['_output'] },
    ObjectTypeDescription
  >({
    description: {
      type: 'object',
      name,
      object: Object.entries(value).reduce<Record<string, TypeDescription>>(
        (map, [key, prop]) => {
          (map as any)[key] = prop.description;
          return map;
        },
        {} as any,
      ),
    },
    validate: (objectValue, path) => {
      if (objectValue === null || objectValue === undefined) {
        return {
          success: false,
          error: new ValidateError(
            'object not defined',
            [...path, name],
            objectValue,
          ),
        };
      }

      if (typeof objectValue !== 'object') {
        return {
          success: false,
          error: new ValidateError(
            'not an object',
            [...path, name],
            objectValue,
          ),
        };
      }

      const result: { [key: string]: any } = {};
      const props = Object.entries(value);
      for (const [propName, propType] of props) {
        const propValue = getValue(objectValue, propName);

        const parsedValue = propType.safeParse(propValue, [
          ...path,
          name,
          propName,
        ]);
        if (!parsedValue.success) {
          return parsedValue;
        }
        result[propName] = parsedValue.data;
      }

      return { success: true, data: result as any };
    },
  });

function getValue(objectValue: object, key: string) {
  if (!!objectValue && key in objectValue) {
    return (objectValue as any)[key];
  } else {
    return undefined;
  }
}
