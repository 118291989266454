<div class="space-x-2">
  <a [routerLink]="['..']">Runners</a>
  <span>/</span>
  <span>{{ storageName }}</span>
</div>

<app-segment-group>
  <app-segment-item [link]="'./events'">Events</app-segment-item>
  <app-segment-item [link]="'./executions'">Executions</app-segment-item>
  <app-segment-item [link]="'./states'">States</app-segment-item>
</app-segment-group>

<router-outlet></router-outlet>
