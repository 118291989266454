import { defineState } from '../../storage/define-state.js';
import { declareState } from '../../storage/declare-state.js';
import { string } from '../../typing/string.js';
import { date } from '../../typing/date.js';

export const AuthManagementState = defineState({
  user: declareState(
    {
      tenant: string(),
      realm: string(),
      provider: string(),
      username: string(),
      password: string(),
      createdAt: date(),
    },
    ['tenant', 'realm', 'provider', 'username'] as const,
  ).index('user', ['tenant', 'realm', 'provider', 'username'] as const), // TODO remove
  userSession: declareState(
    {
      tenant: string(),
      realm: string(),
      provider: string(),
      username: string(),
      refreshToken: string(),
      createdAt: date(),
    },
    ['tenant', 'realm', 'provider', 'username', 'refreshToken'] as const,
  ).index('session', ['tenant', 'realm', 'provider', 'refreshToken'] as const), // TODO remove
});
export type AuthManagementState = typeof AuthManagementState;
