import {
  AggregationDefinition,
  AggregationEventDeclaration,
} from '../realm/aggregation-definition.js';
import { AggregateHostRuntime } from './host-runtime.js';
import { StateDeclarations } from '../storage/state-declaration.js';
import { defineStream } from '../realm/define-stream.js';
import { getOrCreate } from '../utils/get-or-create.js';
import { Type } from '../typing/type.js';
import { transformMap } from '../management/transform-map.js';
import { Handle } from '../realm/handle.js';
import { RuntimeEnv } from '../runtime-env.js';

export interface VersionBuilder {
  source: string;
  resolve(env: RuntimeEnv): Promise<string>;
}

export interface EventSource {
  stream: { version: VersionBuilder; pattern: string };
  handles: { [key: string]: Handle<any, any, any, any> };
  events: { [key: string]: Type<any, any> };
}

export function createAggregateHost<
  TState extends StateDeclarations,
  TEvent extends AggregationEventDeclaration<any>,
>(
  agg: AggregationDefinition<TState, TEvent, any, any>,
): AggregateHostRuntime<TState> {
  const streamSources = Object.entries(agg.events).reduce<{
    [key: string]: EventSource;
  }>((map, [eventKey, handle]) => {
    const value = getOrCreate<EventSource>(
      map,
      `${handle.stream.pattern}`,
      () => ({
        stream: handle.stream,
        handles: {},
        events: {},
      }),
    );

    value.handles[handle.event] = agg.handle[eventKey];
    value.events[handle.event] = handle.type;

    return map;
  }, {});

  return {
    source: agg,
    runtimes: transformMap(streamSources, (source) =>
      defineStream(agg.auth, {
        pattern: source.stream.pattern,
        state: agg.state,
        events: source.events,
        handle: source.handles,
        outputs: {},
      }),
    ),
  };
}
