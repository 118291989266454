import { CreateTodoEvent } from './create-todo.event';
import { EventHandleContext } from '@aion/core/runtime/runtime.js';
import { TodoState } from '../state.js';

export async function createTodo(
  evt: CreateTodoEvent,
  ctx: EventHandleContext<TodoState, {}, {}>,
) {
  if (evt.title.length === 0) {
    return;
  }
  await ctx.state('todos').insert({
    id: ctx.metadata.id,
    title: evt.title,
    completed: false,
    createdAt: ctx.metadata.createdAt,
  });
}
