import { record } from '../typing/record.js';
import { string } from '../typing/string.js';
import { object } from '../typing/object.js';
import { array } from '../typing/array.js';
import { TypeOf } from '../typing/type-of.js';
import { TypeDescriptionSchema } from '../typing/type-description-schema.js';

export const StateDeclarationIndex = record(
  string(),
  object('StateDeclarationIndex', {
    fields: array(string()),
  }),
);
export type StateDeclarationIndex = TypeOf<typeof StateDeclarationIndex>;

export const StreamState = object('StreamState', {
  type: TypeDescriptionSchema,
  indices: StateDeclarationIndex,
  key: array(string()),
});

export type StreamState = TypeOf<typeof StreamState>;
