import {
  AggregationDefinition,
  AggregationEventDeclaration,
  AggregationHandleDeclarations,
  AggregationOptions,
} from './aggregation-definition.js';
import { StateDeclarations } from '../storage/state-declaration.js';
import { AuthDefinition } from './auth-definition.js';
import { RealmDefinition } from './realm-definition.js';
import { createAggregateHost } from '../runtime/create-aggregate-host.js';
import { generateVersion } from './generate-version.js';
import { EventDeclaration } from '../event/event-declarations.js';

export function defineAggregation<
  TState extends StateDeclarations,
  TEvent extends AggregationEventDeclaration<TAuth>,
  THandle extends AggregationHandleDeclarations<TState, TEvent, TAuth>,
  TAuth extends AuthDefinition,
>(
  realm: RealmDefinition<TAuth>,
  opts: AggregationOptions<TState, TEvent, THandle, TAuth>,
) {
  return createAggregateHost(<
    AggregationDefinition<TState, TEvent, THandle, TAuth>
  >{
    realm: realm.name,
    version: generateVersion(
      opts.state,
      // TODO verify if injection info needs to be in version
      Object.entries(opts.events).reduce<{ [key: string]: EventDeclaration }>(
        (map, [key, injected]) => {
          map[key] = injected.type;
          return map;
        },
        {},
      ),
      {},
    ),
    pattern: opts.pattern,
    state: opts.state,
    auth: realm.auth,
    handle: opts.handle,
    events: opts.events,
  });
}
