import { IDBPTransaction } from 'idb';
import {
  StateDefinition,
  TypeOfStateDeclaration,
} from '@aion/core/storage/state-declaration.js';
import { assert } from '@aion/core/typing/assert.js';

export async function insert<TState extends StateDefinition<any, any, any>>(
  trx: IDBPTransaction<unknown, string[], 'readwrite'>,
  storeName: string,
  type: TState,
  val: TypeOfStateDeclaration<TState>,
) {
  assert(val, type.type);
  const store = trx.objectStore(storeName);
  await store.add(val);
}
