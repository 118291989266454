import {
  ChangeDetectionStrategy,
  Component,
  inject,
  OnInit,
  signal,
} from '@angular/core';
import { TodoStateService } from '../todo-state.service';
import { UnauthenticatedSession } from '@aion/browser/unauthenticated-session';
import { AuthenticatedSession } from '@aion/browser/authenticated-session';
import { Router } from '@angular/router';

@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NavbarComponent implements OnInit {
  todoState = inject(TodoStateService);
  router = inject(Router);

  session = signal<AuthenticatedSession | UnauthenticatedSession>({
    type: 'unauthenticated',
  });

  ngOnInit() {
    this.todoState.management.session.resolveAuth().then((session) => {
      this.session.set(session);
    });
  }

  async logout() {
    await this.todoState.management.session.logout();
    this.session.set({
      type: 'unauthenticated',
    });
    await this.router.navigate(['/login']);
  }
}
