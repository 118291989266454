import { OpenIDProvider } from './open-id-provider.js';
import { Secret } from './secret.js';

export interface GoogleOAuthOptions {
  clientId: string;
  clientSecret: Secret;
  scope?: string;
}

export function createGoogleOAuth(
  opts: GoogleOAuthOptions,
): Readonly<OpenIDProvider> {
  return {
    type: 'openid',
    clientId: opts.clientId,
    clientSecret: opts.clientSecret,

    audience: undefined,
    issuer: 'https://accounts.google.com',

    // openIdConfigurationUrl: 'https://accounts.google.com/.well-known/openid-configuration',
    token: {
      url: 'https://oauth2.googleapis.com/token',
      formData: {
        tenant: 'common',
      },
    },

    jwks: {
      url: 'https://www.googleapis.com/oauth2/v3/certs',
    },

    authorize: {
      url: 'https://accounts.google.com/o/oauth2/v2/auth',
      queryParams: {
        client_id: opts.clientId,
        response_type: 'code',
        access_type: 'offline',
        scope: opts.scope ?? 'openid email profile',
      },
    },
  };
}
