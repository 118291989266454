import { TypeDescription } from './type-description.js';
import { union } from './union.js';
import { record } from './record.js';
import { optional } from './optional.js';
import { nullable } from './nullable.js';
import { numberAsText } from './number-as-text.js';
import { date } from './date.js';
import { literal } from './literal.js';
import { array } from './array.js';
import { string } from './string.js';
import { number } from './number.js';
import { boolean } from './boolean.js';
import { object } from './object.js';
import { Type } from './type.js';
import { failNever } from '../utils/fail-never.js';
import { func } from './func.js';
import { promise } from './promise.js';

export function buildValidator(type: TypeDescription): Type<any, any> {
  if (type.type === 'boolean') {
    return boolean();
  } else if (type.type === 'string') {
    return string();
  } else if (type.type === 'numberAsText') {
    return numberAsText();
  } else if (type.type === 'number') {
    return number();
  } else if (type.type === 'date') {
    return date();
  } else if (type.type === 'array') {
    return array(buildValidator(type.itemType));
  } else if (type.type === 'literal') {
    return literal(type.constant);
  } else if (type.type === 'optional') {
    return optional(buildValidator(type.optionalType));
  } else if (type.type === 'object') {
    return object(
      type.name,
      Object.entries(type.object).reduce((map, [key, prop]) => {
        map[key] = buildValidator(prop as any);
        return map;
      }, {} as any) as any,
    );
  } else if (type.type === 'union') {
    return union(
      type.name,
      type.unionTypes.map((u) => buildValidator(u)) as any,
    );
  } else if (type.type === 'record') {
    return record(buildValidator(type.keyType), buildValidator(type.valueType));
  } else if (type.type === 'nullable') {
    return nullable(buildValidator(type.nullableType));
  } else if (type.type === 'func') {
    return func(
      type.argumentTypes.map((a) => buildValidator(a)),
      buildValidator(type.returnType),
    );
  } else if (type.type === 'promise') {
    return promise(buildValidator(type.returnType));
  } else {
    failNever(type, 'unknown type');
  }
}
