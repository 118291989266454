import { Component, inject, OnInit } from '@angular/core';
import { AionStateService } from '../aion-state.service';
import { ActivatedRoute } from '@angular/router';
import { SnapshotClient } from '@aion/core/storage/snapshot-client.js';

@Component({
  selector: 'app-state-detail',
  templateUrl: './state-detail.component.html',
  styleUrls: ['./state-detail.component.scss'],
})
export class StateDetailComponent implements OnInit {
  private aionState = inject(AionStateService);
  private route = inject(ActivatedRoute);

  client!: SnapshotClient<any>;
  stateName!: string;

  ngOnInit() {
    const storageName = this.route.snapshot.params['storageName'];
    this.stateName = this.route.snapshot.params['stateName'];
    this.client = this.aionState.getAppState(storageName, this.stateName);
  }
}
