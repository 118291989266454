import { Type } from './type.js';
import { createType } from './create-type.js';
import { OptionalTypeDescription } from './optional-type-description.js';

export const optional = <T extends Type<any, any>>(type: T) =>
  createType<T['_output'] | undefined, OptionalTypeDescription>({
    description: { type: 'optional', optionalType: type.description },
    validate: (value, path) => {
      if (value === null || value === undefined) {
        return { success: true, data: undefined };
      } else {
        return type.safeParse(value, path);
      }
    },
  });
