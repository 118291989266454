import { createType } from './create-type.js';
import { ValidateError } from './validate-error.js';
import { Type } from './type.js';
import { BooleanTypeDescription } from './boolean-type-description.js';

export const boolean: () => Type<boolean, BooleanTypeDescription> = () =>
  createType<boolean, BooleanTypeDescription>({
    description: { type: 'boolean' },
    validate: (val, path) => {
      if (typeof val === 'boolean') {
        return { success: true, data: val };
      } else {
        return {
          success: false,
          error: new ValidateError('not a valid boolean', path, val),
        };
      }
    },
  });
