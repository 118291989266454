import {
  AggregationRegistration,
  AuthProviders,
  RealmRegistry,
  StreamRegistration,
} from './create-realm-registry.js';
import {
  AggregateHostRuntime,
  StreamHostRuntime,
} from '../runtime/host-runtime.js';
import { RealmDefinition } from './realm-definition.js';
import { buildMap } from './build-map.js';
import { CustomError } from '../custom-error.js';
import { RuntimeEnv } from '../runtime-env.js';
import { getOrFail, getOrFailAsync } from './get-or-fail.js';
import { createLazyAsyncMap } from './create-lazy-async-map.js';
import { DefinitionIdentifier } from './definition-identifier.js';
import { mapAggregationRuntimes } from './map-aggregation-runtimes.js';
import { InjectedEvent } from './aggregation-definition.js';
import { mapEventDeclarations } from '../management/map-event-declarations.js';
import { mapStateDeclarations } from '../management/map-state-declarations.js';
import { mapAggregationEventSource } from '../management/map-aggregation-event-source.js';

export interface StaticRegistryOptions {
  streams?: StreamHostRuntime<any, any>[];
  aggregations?: AggregateHostRuntime<any>[];
  realms?: RealmDefinition<any>[];
  env: RuntimeEnv;
  tenant: string;
}

export function createStaticRegistry(
  opts: StaticRegistryOptions,
): RealmRegistry {
  const streams = createLazyAsyncMap(
    opts.streams ?? [],
    async (stream) =>
      <StreamRegistration>{
        state: mapStateDeclarations(stream.source.state),
        events: mapEventDeclarations(stream.source.events),
        runtime: stream.runtime,
        identifier: {
          version: await stream.source.version.resolve(opts.env),
          realm: stream.source.realm,
          tenant: opts.tenant,
          pattern: stream.source.pattern,
        },
      },
    (stream) =>
      `${stream.identifier.realm}:${stream.identifier.pattern}:${stream.identifier.version}`,
  );
  const aggregations = createLazyAsyncMap(
    opts.aggregations ?? [],
    async (agg) =>
      <AggregationRegistration>{
        state: mapStateDeclarations(agg.source.state),
        events: await mapAggregationEventSource(agg.source.events, opts.env),
        runtimes: mapAggregationRuntimes(agg),
        identifier: {
          version: await agg.source.version.resolve(opts.env),
          realm: agg.source.realm,
          tenant: opts.tenant,
          pattern: agg.source.pattern,
        },
      },
    (agg) =>
      `${agg.identifier.realm}:${agg.identifier.pattern}:${agg.identifier.version}`,
  );
  const auth = buildMap(opts.realms ?? [], (realm) => `${realm.name}`);
  return {
    async getStream(
      params: DefinitionIdentifier,
    ): Promise<StreamRegistration | null> {
      return getOrFailAsync(
        streams,
        `${params.realm}:${params.pattern}:${params.version}`,
      );
    },
    async *listRealm() {
      for (const realm of opts.realms ?? []) {
        yield {
          tenant: opts.tenant,
          name: realm.name,
        };
      }
    },
    async *listAggregation(
      source: DefinitionIdentifier,
    ): AsyncGenerator<AggregationRegistration> {
      for await (const agg of opts.aggregations ?? []) {
        const events = Object.values(agg.source.events) as InjectedEvent<
          any,
          any
        >[];
        for (const e of events) {
          if (
            e.stream.pattern === source.pattern &&
            (await e.stream.version.resolve(opts.env)) === source.version
          ) {
            yield await getOrFailAsync(
              aggregations,
              `${agg.source.realm}:${agg.source.pattern}:${await agg.source.version.resolve(opts.env)}`,
            );
            break;
          }
        }
      }
    },
    async getAuth(tenant: string, realm: string): Promise<AuthProviders> {
      const result = getOrFail(auth, `${realm}`);
      return {
        providers: result.auth,
      };
    },
    async getAggregation(
      params: DefinitionIdentifier,
    ): Promise<AggregationRegistration | null> {
      return getOrFailAsync(
        aggregations,
        `${params.realm}:${params.pattern}:${params.version}`,
      );
    },
    upload(): Promise<void> {
      throw new CustomError('not allowed on static registry', null, {});
    },
  };
}
