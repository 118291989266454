import { StateDeclarations } from '../storage/state-declaration.js';
import { EventDeclarations } from '../event/event-declarations.js';
import { OutputDeclarations } from './output-declarations.js';
import { RealmDefinition } from './realm-definition.js';
import { AuthDefinition } from './auth-definition.js';
import { HandleDeclarations } from './handle-declarations.js';
import { StreamOptions } from './stream-options.js';
import { createStreamHost } from '../runtime/create-stream-host.js';
import { generateVersion } from './generate-version.js';
import { StreamHostRuntime } from '../runtime/host-runtime.js';

export function defineStream<
  TState extends StateDeclarations,
  TEvent extends EventDeclarations,
  THandle extends HandleDeclarations<TState, TEvent, TOutput>,
  TOutput extends OutputDeclarations,
  TAuth extends AuthDefinition,
>(
  realm: RealmDefinition<TAuth>,
  opts: StreamOptions<TState, TEvent, THandle, TOutput>,
): StreamHostRuntime<TState, TEvent> {
  return createStreamHost({
    realm: realm.name,
    version: generateVersion(opts.state, opts.events, opts.outputs),
    outputs: opts.outputs,
    state: opts.state,
    events: opts.events,
    auth: realm.auth,
    pattern: opts.pattern,
    handle: opts.handle,
  });
}
