import { Type } from './type.js';
import { createType } from './create-type.js';
import { ValidateError } from './validate-error.js';
import { RecordTypeDescription } from './record-type-description.js';

export const record = <T extends Type<any, any>, K extends Type<any, any>>(
  keyType: K,
  valueType: T,
) =>
  createType<
    {
      [key: string]: T['_output'];
    },
    RecordTypeDescription
  >({
    description: {
      type: 'record',
      keyType: keyType.description,
      valueType: valueType.description,
    },
    validate: (val, path) => {
      if (val === null || val === undefined) {
        return {
          success: false,
          error: new ValidateError('object not defined', path, val),
        };
      }

      if (typeof val !== 'object') {
        return {
          success: false,
          error: new ValidateError('not an object', path, val),
        };
      }

      const result: { [key: string]: any } = {};
      const keys = Object.entries(val);
      for (const [key, keyValue] of keys) {
        const keyValidation = keyType.safeParse(key, [...path, key]);
        if (!keyValidation.success) {
          return keyValidation;
        }

        const prop = valueType.safeParse(keyValue, [...path, key]);
        if (!prop.success) {
          return prop;
        }
        result[key] = prop.data;
      }

      return { success: true, data: result };
    },
  });
