import { Lazy } from './lazy.js';

export function lazyWrap<T, R>(
  value: Lazy<T>,
  wrapper: (val: T) => R,
): Lazy<R> {
  let promise: Promise<R> | undefined = undefined;
  return {
    resolve() {
      if (!promise) {
        promise = value.resolve().then((r) => wrapper(r));
      }
      return promise;
    },
    close(fn: (val: R) => Promise<void> | void): Promise<void> {
      return value.close((r) => fn(wrapper(r)));
    },
  };
}
