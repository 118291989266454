import { object } from '@aion/core/typing/object.js';
import { string } from '@aion/core/typing/string.js';
import { TypeOf } from '@aion/core/typing/type-of.js';

export const OAuthTransferStateData = object('OAuthTransferStateData', {
  redirectUri: string(),
  provider: string(),
  tenant: string(),
  realm: string(),
});
export type OAuthTransferStateData = TypeOf<typeof OAuthTransferStateData>;
