import { ChangeDetectionStrategy, Component, inject } from '@angular/core';
import { Router } from '@angular/router';
import { TodoStateService } from '../todo-state.service';
import { groupStream } from '../../state/group/stream';

@Component({
  selector: 'app-group-create-modal',
  templateUrl: './group-create-modal.component.html',
  styleUrls: ['./group-create-modal.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class GroupCreateModalComponent {
  private router = inject(Router);
  private todoState = inject(TodoStateService);

  name?: string;

  dismiss() {
    this.router.navigate([{ outlets: { modal: [] } }], {});
  }

  async create() {
    if (!this.name) {
      return;
    }

    const groupId = Math.random().toString(36);
    const stream = this.todoState.management.getStream(groupStream, {
      args: {
        groupId: groupId,
      },
    });
    await stream.push('create-group', { name: this.name, groupId: groupId });
    await this.router.navigate([{ outlets: { modal: [] } }], {});
    await this.router.navigate(['/', groupId]);
  }
}
