import {
  deleteDB,
  IDBPDatabase,
  IDBPTransaction,
  openDB,
  StoreNames,
} from 'idb';
import {
  StateDefinitions,
  StateIndex,
} from '@aion/core/storage/state-declaration.js';
import { getOrCreate } from '@aion/core/utils/get-or-create.js';
import { StorageProvider } from '@aion/core/management/storage-provider.js';
import { StorageInterface } from '@aion/core/storage/storage-interface.js';
import { Lazy } from '@aion/core/management/lazy.js';
import { createLazy } from '@aion/core/management/create-lazy.js';
import { createIndexeddbStorage } from './create-indexeddb-storage.js';

export function createIndexeddbProvider(): StorageProvider {
  const names: {
    [key: string]: Lazy<IDBPDatabase>;
  } = {};

  return {
    open<TState extends StateDefinitions>(
      name: string,
      state: TState,
    ): StorageInterface<TState> {
      const db = getOrCreate(names, name, () => createDb(name, state));
      return createIndexeddbStorage(db, state);
    },
    async destroy(name: string): Promise<void> {
      await this.close(name);
      await deleteDB(name);
    },
    async close(name: string): Promise<void> {
      const existing = names[name];
      if (!existing) {
        return;
      }
      await existing.close((db) => db.close());
      delete names[name];
    },
    async *sessions(): AsyncGenerator<string> {
      for (const session of Object.keys(names)) {
        yield session;
      }
    },
  };
}

function createDb(dbName: string, state: StateDefinitions): Lazy<IDBPDatabase> {
  return createLazy(async () => {
    const db = await openDB<any>(dbName, undefined, {
      upgrade(database: IDBPDatabase<any>) {
        for (const [key, type] of Object.entries(state)) {
          const store = database.createObjectStore(key, {
            keyPath: type.key,
          });

          if (type.indices) {
            for (const [indexName, index] of Object.entries(type.indices)) {
              const fields = (index as StateIndex<any, string[]>).fields;
              store.createIndex(indexName, fields);
            }
          }
        }
      },
    });
    return ensureUpToDate(db, dbName, state);
  });
}

export async function ensureUpToDate(
  db: IDBPDatabase,
  dbName: string,
  state: StateDefinitions,
): Promise<IDBPDatabase> {
  if (Object.keys(state).length === 0) {
    return db;
  }

  const objectStoreNames = Object.keys(state);
  const missingStores = objectStoreNames.filter(
    (key) => !db.objectStoreNames.contains(key),
  );
  const existingStores = objectStoreNames.filter((key) =>
    db.objectStoreNames.contains(key),
  );
  const missingIndices: {
    store: string;
    index: string;
    fields: string[];
    exists: boolean;
  }[] = [];
  for (const [storeName, declaration] of Object.entries(state)) {
    if (missingStores.indexOf(storeName) >= 0) {
      for (const [indexName, index] of Object.entries(declaration.indices)) {
        const indexFields: string[] = (index as StateIndex<any, string[]>)
          .fields;
        missingIndices.push({
          store: storeName,
          index: indexName,
          fields: indexFields,
          exists: false,
        });
      }
    }
  }
  if (existingStores.length > 0) {
    const trx = db.transaction(existingStores, 'readonly');

    for (const [objectName, type] of Object.entries(state)) {
      const indexNames =
        missingStores.indexOf(objectName) >= 0
          ? null
          : trx.objectStore(objectName).indexNames;

      if (type.indices) {
        for (const [indexName, index] of Object.entries(type.indices)) {
          // TODO typing??
          const indexFields: string[] = (index as StateIndex<any, string[]>)
            .fields;

          if (!indexNames || !indexNames.contains(indexName)) {
            missingIndices.push({
              store: objectName,
              index: indexName,
              fields: indexFields,
              exists: false,
            });
          } else {
            const keyPath = trx
              .objectStore(objectName)
              .index(indexName).keyPath;
            const keyPaths = typeof keyPath === 'string' ? [keyPath] : keyPath;
            if (
              indexFields.length !== keyPaths.length ||
              indexFields.some((f) => keyPaths.indexOf(f) === -1)
            ) {
              missingIndices.push({
                store: objectName,
                index: indexName,
                fields: indexFields,
                exists: true,
              });
            }
          }
        }
      }
    }

    await trx.done;
  }

  if (missingStores.length > 0 || missingIndices.length > 0) {
    db.close();

    return openDB<any>(dbName, db.version + 1, {
      upgrade(
        database: IDBPDatabase<any>,
        oldVersion: number,
        newVersion: number | null,
        transaction: IDBPTransaction<any, StoreNames<any>[], 'versionchange'>,
      ) {
        for (const storeName of missingStores) {
          const store = database.createObjectStore(storeName, {
            keyPath: 'id',
          });
          for (const index of missingIndices.filter(
            (i) => i.store === storeName,
          )) {
            store.createIndex(index.index, index.fields);
          }
        }

        const indexesOnExistingStores = missingIndices.filter(
          (i) => missingStores.indexOf(i.store) === -1,
        );
        if (indexesOnExistingStores.length > 0) {
          for (const index of indexesOnExistingStores) {
            const store = transaction.objectStore(index.store);
            if (index.exists) {
              store.deleteIndex(index.index);
            }
            store.createIndex(index.index, index.fields);
          }
        }
      },
    }).then((r) => {
      return r;
    });
  } else {
    return db;
  }
}
