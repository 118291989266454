import { StateDefinitions } from '../storage/state-declaration.js';
import { ManagementContext } from './management-context.js';
import { ManagementAggregateData } from './management-aggregate-data.js';
import { AggregateInterface } from './aggregate-interface.js';
import { createLazy } from './create-lazy.js';
import { SnapshotClient } from '../storage/snapshot-client.js';
import { createSnapshot } from './create-snapshot.js';
import { lazyWrap } from './lazy-wrap.js';
import { ObservedClient } from '../storage/observer/observed-client.js';
import { observeClient } from '../storage/observer/observed-state.js';
import { aggregationInitTopic } from './aggregation-attach-topic.js';
import { createInstanceStorage } from './create-instance-storage.js';
import { mapStateDeclarations } from './map-state-declarations.js';
import { createLockKey } from '../lock/create-lock-key.js';

export function createAggregateInterface<TState extends StateDefinitions>(
  management: ManagementContext,
  aggregation: ManagementAggregateData<TState>,
): AggregateInterface<TState> {
  const lazyRegistration = createLazy(async () => {
    const id = await aggregation.identifier.resolve();

    const storage = createInstanceStorage(
      id,
      management.storageBuilder,
      aggregation.state,
    );

    const existing = await storage.management.snapshot('aggregations').get({
      realm: id.realm,
      pattern: id.pattern,
      version: id.version,
      tenant: id.tenant,
      name: id.name,
    });

    if (!existing) {
      const topic = await management.queue.createTopic(aggregationInitTopic);

      await management.lock.acquire(createLockKey(id), async () => {
        const existing = await storage.management.snapshot('aggregations').get({
          realm: id.realm,
          pattern: id.pattern,
          version: id.version,
          name: id.name,
          tenant: id.tenant,
        });
        if (existing) {
          return;
        }

        const events = await aggregation.events.resolve();
        await storage.storage.storage('management').transaction(async (trx) => {
          await trx.state('aggregations').insert({
            authId: id.auth.key,
            version: id.version,
            pattern: id.pattern,
            tenant: id.tenant,
            args: id.args,
            realm: id.realm,
            authSub: id.auth.sub,
            authAud: id.auth.aud,
            authIss: id.auth.iss,
            name: id.name,
            events: events,
            state: mapStateDeclarations(aggregation.state),
          });

          for (const state of Object.keys(aggregation.state)) {
            await trx.state('permissions').insert({
              authId: id.auth.key,
              name: id.name,
              version: id.version,
              realm: id.realm,
              tenant: id.tenant,
              pattern: id.pattern,
              type: `state:${state}`,
              write: false,
              read: true,
            });
          }
        });

        await topic.publish(
          {
            identifier: id,
          },
          {
            attributes: {
              name: id.name,
              tenant: id.tenant,
              realm: id.realm,
              pattern: id.pattern,
              version: id.version,
            },
          },
        );
      });
    }

    return storage;
  });

  return {
    state: aggregation.state,
    observe<K extends string & keyof TState>(
      name: K,
    ): ObservedClient<TState[K]> {
      return observeClient(
        aggregation.state[name],
        lazyWrap(lazyRegistration, (r) => r.stateStorage),
        name,
      );
    },
    snapshot<K extends string & keyof TState>(
      name: K,
    ): SnapshotClient<TState[K]> {
      return createSnapshot(
        lazyWrap(lazyRegistration, (r) => r.stateStorage),
        aggregation.state,
        name,
      );
    },
  };
}
