import {
  StateDefinition,
  StateIndex,
} from '@aion/core/storage/state-declaration.js';
import {
  FilterKey,
  FilterStateIndex,
  FilterValue,
  RangeOptions,
  SnapshotIndexClient,
} from '@aion/core/storage/snapshot-index-client.js';
import { RangeResult } from '@aion/core/storage/browse-result.js';
import { iterateResponse } from './iterate-response.js';
import { RemoteDescription } from './remote-description.js';
import { RpcClient } from '../api/create-rpc-client.js';
import { SessionManager } from '../auth/jwt-session.js';
import { Lazy } from '@aion/core/management/lazy.js';
import { createFakeSignal } from './fake-signal.js';
import { StateValue } from '@aion/core/storage/state-value.js';

export function createRemoteSnapshotIndexClient<
  TState extends StateDefinition<any, any, any>,
  TIndex extends StateIndex<any, any>,
>(
  state: TState,
  index: TIndex,
  keyIndex: number,
  rpc: RpcClient,
  session: SessionManager,
  lazyVersion: Lazy<string>,
  type: 'stream' | 'aggregation',
  streamIdentifier: RemoteDescription,
  stateName: string,
  indexName: string,
  filter: Record<string, StateValue>,
): SnapshotIndexClient<TState, TIndex> {
  return {
    nextKey: index.fields[keyIndex] ?? null,
    async *range(
      bookmark: FilterValue<TState, TIndex> | null,
      options?: Partial<RangeOptions>,
    ): AsyncGenerator<RangeResult<any>> {
      yield* iterateResponse(
        state,
        bookmark ?? null,
        options,
        async (bookmark, rangeOptions) =>
          rpc['state.index.browse'].call(
            {
              tenant: streamIdentifier.tenant,
              realm: streamIdentifier.realm,
              version: await lazyVersion.resolve(),
              pattern: streamIdentifier.pattern,
              state: stateName,
              index: indexName,
              type,
              bookmark: bookmark ?? undefined,
              open: rangeOptions.open,
              direction: rangeOptions.dir,
              filter,
              args: streamIdentifier.args,
              token: await session.resolveToken(),
              take: undefined,
            },
            createFakeSignal(),
          ),
      );
    },
    filter(
      key: FilterKey<TIndex> & string,
      value: FilterValue<TState, TIndex>,
    ): SnapshotIndexClient<TState, FilterStateIndex<TIndex>> {
      return createRemoteSnapshotIndexClient(
        state,
        index,
        keyIndex + 1,
        rpc,
        session,
        lazyVersion,
        type,
        streamIdentifier,
        stateName,
        indexName,
        { ...filter, [key]: value },
      );
    },
  };
}
