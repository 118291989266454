import { object } from '../typing/object.js';
import { literal } from '../typing/literal.js';
import { string } from '../typing/string.js';
import { union } from '../typing/union.js';
import { TypeOf } from '../typing/type-of.js';
import { record } from '../typing/record.js';
import { StateValue } from './state-value.js';

export const TransactionDeleteOperation = object('TransactionDeleteOperation', {
  type: literal('delete'),
  state: string(),
  keys: record(string(), StateValue),
});

export const TransactionPatchOperation = object('TransactionPatchOperation', {
  type: literal('patch'),
  state: string(),
  keys: record(string(), StateValue),
  data: record(string(), StateValue),
});

export const TransactionUpsertOperation = object('TransactionUpsertOperation', {
  type: literal('upsert'),
  state: string(),
  keys: record(string(), StateValue),
  data: record(string(), StateValue),
});

export const TransactionInsertOperation = object('TransactionInsertOperation', {
  type: literal('insert'),
  state: string(),
  keys: record(string(), StateValue),
  data: record(string(), StateValue),
});

export const TransactionOperation = union('TransactionOperation', [
  TransactionDeleteOperation,
  TransactionPatchOperation,
  TransactionInsertOperation,
  TransactionUpsertOperation,
]);
export type TransactionOperation = TypeOf<typeof TransactionOperation>;
