import { CompleteTodoEvent } from './complete-todo.event';
import { EventHandleContext } from '@aion/core/runtime/runtime.js';
import { TodoState } from '../state.js';

export async function completeTodo(
  evt: CompleteTodoEvent,
  ctx: EventHandleContext<TodoState, {}, {}>,
) {
  await ctx.state('todos').patch({
    id: evt.id,
    completed: true,
  });
}
