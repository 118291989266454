import { object } from '../typing/object.js';
import { string } from '../typing/string.js';
import { record } from '../typing/record.js';
import { TypeOf } from '../typing/type-of.js';
import { StreamState } from './stream-state.js';
import { EventSource } from './event-source.js';
import { RuntimeDeclaration } from './runtime-declaration.js';

export const AggregationDeclaration = object('AggregationDeclaration', {
  pattern: string(),
  version: string(),
  realm: string(),

  runtime: RuntimeDeclaration,

  states: record(string(), StreamState),
  events: record(string(), EventSource),
});

export type AggregationDeclaration = TypeOf<typeof AggregationDeclaration>;
