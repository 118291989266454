import { GroupContext } from '../context';
import { InviteUserEvent } from './invite-user.event';

export function inviteUser(evt: InviteUserEvent, ctx: GroupContext) {
  if (evt.name.length === 0) {
    return;
  }
  ctx.state('groupMembers').insert({
    id: ctx.metadata.id,
    name: evt.name,
    email: evt.email,
    state: 'invited',
    auth: evt.user,
    createdAt: ctx.metadata.createdAt,
  });
  ctx.stream.allowWrite(evt.user, ['accept-invite']);
}
