import { object } from '@aion/core/typing/object.js';
import { string } from '@aion/core/typing/string.js';
import { InstanceType } from '@aion/core/management/instance-identifier.js';
import { StreamArgs } from './stream-args.js';
import { optional } from '@aion/core/typing/optional.js';
import { SortDirection } from './sort-direction.js';
import { boolean } from '@aion/core/typing/boolean.js';
import { number } from '@aion/core/typing/number.js';
import { record } from '@aion/core/typing/record.js';
import { StateValue } from '@aion/core/storage/state-value.js';

export const StateBrowse = object('StateBrowse', {
  realm: string(),
  tenant: string(),
  version: string(),
  pattern: string(),
  type: InstanceType,
  args: StreamArgs,
  state: string(),

  bookmark: optional(record(string(), StateValue)),
  direction: optional(SortDirection),
  open: optional(boolean()),
  take: optional(number()),

  token: optional(string()),
});
