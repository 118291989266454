import { defineTopic } from '../queue/define-topic.js';
import { object } from '../typing/object.js';
import { string } from '../typing/string.js';
import { StoredEvent } from '../event/stored-event.js';
import { InstanceIdentifier } from './instance-identifier.js';
import { StateUpdate } from '../storage/tracker/state-update.js';

export const aggregationAttachTopic = defineTopic('aggregation.attach', {
  attributes: ['tenant', 'realm', 'version', 'pattern'] as const,
  data: object('AggregationAttach', {
    stream: InstanceIdentifier,
    aggregation: InstanceIdentifier,
  }),
});

export const streamEventTopic = defineTopic('stream.event', {
  attributes: ['tenant', 'realm', 'name', 'version', 'pattern'] as const,
  data: object('StreamEvent', {
    event: StoredEvent,
    identifier: InstanceIdentifier,
  }),
});

export const aggregationEventTopic = defineTopic('aggregation.event', {
  attributes: ['tenant', 'realm'] as const,
  data: object('AggregationEvent', {
    event: StoredEvent,
    stream: InstanceIdentifier,
  }),
});

export const stateCommitTopic = defineTopic('state.commit', {
  attributes: ['tenant', 'realm', 'name', 'version', 'eventId'] as const,
  data: object('StateCommit', {
    eventId: string(),
    identifier: InstanceIdentifier,
  }),
});

export const streamInitTopic = defineTopic('stream.init', {
  attributes: ['tenant', 'realm', 'name', 'version', 'pattern'] as const,
  data: object('StreamInit', {
    identifier: InstanceIdentifier,
  }),
});

export const aggregationInitTopic = defineTopic('aggregation.init', {
  attributes: ['tenant', 'realm', 'name', 'version', 'pattern'] as const,
  data: object('AggregationInit', {
    identifier: InstanceIdentifier,
  }),
});

export const stateUpdateTopic = defineTopic('state.update', {
  attributes: ['storageName', 'state'] as const,
  data: object('StateUpdate', {
    update: StateUpdate,
  }),
});
