import { ContinuousCmd, ReturnCmd } from './return-cmd.js';
import { Type } from '../../typing/type.js';

export interface Cmd<I> {
  type: 'cmd';

  input: Type<I, any>;

  returns<O>(output: Type<O, any>): ReturnCmd<I, O>;

  continuous<O>(output: Type<O, any>): ContinuousCmd<I, O>;
}

export function cmd<I>(input: Type<I, any>): Cmd<I> {
  return {
    type: 'cmd',
    input,
    returns<O>(output: Type<O, any>): ReturnCmd<I, O> {
      return { type: 'return', input, output };
    },
    continuous<O>(output: Type<O, any>): ContinuousCmd<I, O> {
      return { type: 'continuous', input, output };
    },
  };
}
