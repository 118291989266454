import { object } from '../typing/object.js';
import { string } from '../typing/string.js';
import { TypeOf } from '../typing/type-of.js';

export const EventAuth = object('EventAuth', {
  sub: string(),
  aud: string(),
  iss: string(),
});
export type EventAuth = TypeOf<typeof EventAuth>;

export function fallbackAuth(auth?: EventAuth | null | undefined): EventAuth {
  return auth ?? { sub: 'anonymous', aud: 'anonymous', iss: 'anonymous' };
}

export function isAnonymousAuth(auth: EventAuth): boolean {
  return (
    auth.sub === 'anonymous' &&
    auth.aud === 'anonymous' &&
    auth.iss === 'anonymous'
  );
}
