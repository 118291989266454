import { HttpClient, RequestOptions } from './http-client.js';
import { HttpMethod } from './http-method.js';
import { HttpClientResponse } from './http-client-response.js';
import { HttpAdapter } from './http-adapter.js';
import { HttpClientConfig } from './http-client-config.js';
import { HttpClientMiddleware } from './http-client-middleware.js';

export function createHttpClient<Response extends HttpClientResponse>(
  adapter: HttpAdapter<Response>,
  config: HttpClientConfig,
): HttpClient<Response> {
  return {
    delete(opts: RequestOptions): Promise<Response> {
      return processPath(adapter, config, 'DELETE', opts);
    },

    get(opts: RequestOptions): Promise<Response> {
      return processPath(adapter, config, 'GET', opts);
    },

    head(opts: RequestOptions): Promise<Response> {
      return processPath(adapter, config, 'HEAD', opts);
    },

    options(opts: RequestOptions): Promise<Response> {
      return processPath(adapter, config, 'OPTIONS', opts);
    },

    post(opts: RequestOptions): Promise<Response> {
      return processPath(adapter, config, 'POST', opts);
    },

    put(opts: RequestOptions): Promise<Response> {
      return processPath(adapter, config, 'PUT', opts);
    },

    patch(opts: RequestOptions): Promise<Response> {
      return processPath(adapter, config, 'PATCH', opts);
    },

    body(body: string): HttpClient<Response> {
      return createHttpClient(adapter, {
        ...config,
        body,
      });
    },

    header(name: string, value: string | string[]): HttpClient<Response> {
      return createHttpClient(adapter, {
        ...config,
        headers: { ...config.headers, [name]: value },
      });
    },

    path(path: string): HttpClient<Response> {
      return createHttpClient(adapter, {
        ...config,
        paths: [...config.paths, path],
      });
    },

    query(name: string, value: string | string[]): HttpClient<Response> {
      return createHttpClient(adapter, {
        ...config,
        query: { ...config.query, [name]: value },
      });
    },

    use<TNewResponse>(
      middleware: HttpClientMiddleware<any, TNewResponse>,
    ): HttpClient<Response & TNewResponse> {
      return createHttpClient<Response & TNewResponse>(
        {
          async send(
            config: HttpClientConfig,
            method: HttpMethod,
            signal: AbortSignal,
          ): Promise<Response & TNewResponse> {
            const response = await adapter.send(config, method, signal);
            return middleware.handleResponse(response);
          },
        },
        {
          ...config,
          middlewares: [...config.middlewares, middleware],
        },
      );
    },
  };
}

function processPath<Response extends HttpClientResponse>(
  adapter: HttpAdapter<Response>,
  config: HttpClientConfig,
  method: HttpMethod,
  opts: RequestOptions,
) {
  if (opts.path) {
    return process(
      adapter,
      {
        ...config,
        paths: [...config.paths, opts.path],
      },
      method,
      opts.signal,
    );
  } else {
    return process(adapter, config, method, opts.signal);
  }
}

function process<Response extends HttpClientResponse>(
  adapter: HttpAdapter<Response>,
  config: HttpClientConfig,
  method: HttpMethod,
  signal: AbortSignal,
) {
  return adapter.send(config, method, signal);
}
