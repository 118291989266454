import {
  StateDefinition,
  StateDefinitions,
  StateIndex,
  StateKeyValues,
  TypeOfStateDeclaration,
} from '../state-declaration.js';
import { ObservedClient } from './observed-client.js';
import {
  FilterKey,
  FilterStateIndex,
  FilterValue,
} from '../snapshot-index-client.js';
import { BrowseResult, RangeResult } from '../browse-result.js';
import { ObservedIndexClient } from './observed-index-client.js';
import { Lazy } from '../../management/lazy.js';
import { ObserveRangeOptions } from './observe-range-options.js';
import { IterateOptions } from './iterate-options.js';
import { TrackedView } from '../tracker/tracked-state.js';
import { lazyWrap } from '../../management/lazy-wrap.js';

export function observeClient<
  TState extends StateDefinitions,
  K extends string & keyof TState,
>(
  state: TState[K],
  lazyClient: Lazy<TrackedView<TState>>,
  stateName: K,
): ObservedClient<TState[K]> {
  return {
    async *observeRange(
      bookmark: StateKeyValues<TState[K]> | null,
      opts: ObserveRangeOptions,
    ): AsyncGenerator<BrowseResult<TypeOfStateDeclaration<TState[K]>>> {
      const observer = await lazyClient.resolve();
      const iterator = observer.observe(stateName).observeRange(bookmark, opts);
      yield* iterator;
    },
    async *iterate(
      bookmark: StateKeyValues<TState[K]> | null,
      opts: IterateOptions,
    ): AsyncGenerator<RangeResult<TypeOfStateDeclaration<TState[K]>>> {
      const client = await lazyClient.resolve();
      for await (const src of client
        .observe(stateName)
        .iterate(bookmark, opts)) {
        yield src;
      }
    },
    async *observe(
      id: StateKeyValues<TState[K]>,
      opts,
    ): AsyncGenerator<TypeOfStateDeclaration<TState[K]> | null> {
      const observer = await lazyClient.resolve();
      yield* observer.observe(stateName).observe(id, opts);
    },
    index<I extends keyof TState[K]['indices'] & string>(
      index: I,
    ): ObservedIndexClient<TState[K], TState[K]['indices'][I]> {
      return observeIndexClient(
        state.indices[index],
        0,
        lazyWrap(lazyClient, (c) => c.observe(stateName).index(index)),
      );
    },
  };
}

export function observeIndexClient<
  TState extends StateDefinition<any, any, any>,
  TIndex extends StateIndex<any, any>,
>(
  indexDefinition: TIndex,
  keyIndex: number,
  lazyObserver: Lazy<ObservedIndexClient<TState, TIndex>>,
): ObservedIndexClient<TState, TIndex> {
  return {
    nextKey: indexDefinition.fields[keyIndex] ?? null,
    async *observeRange(
      bookmark: FilterValue<TState, TIndex> | null,
      opts: ObserveRangeOptions,
    ): AsyncGenerator<BrowseResult<TypeOfStateDeclaration<TState>>> {
      const observer = await lazyObserver.resolve();
      const iterator = observer.observeRange(bookmark, opts);
      yield* iterator;
    },
    async *iterate(
      bookmark: FilterValue<TState, TIndex> | null,
      opts: IterateOptions,
    ): AsyncGenerator<RangeResult<TypeOfStateDeclaration<TState>>> {
      const observer = await lazyObserver.resolve();
      for await (const src of observer.iterate(bookmark, opts)) {
        yield src;
      }
    },
    filter(
      key: FilterKey<TIndex> & string,
      value: FilterValue<TState, TIndex>,
    ): ObservedIndexClient<TState, FilterStateIndex<TIndex>> {
      return observeIndexClient(
        indexDefinition,
        keyIndex + 1,
        lazyWrap(lazyObserver, (c) => c.filter(key, value)),
      );
    },
  };
}
