import { InstanceStorage } from './instance-storage.js';
import { StoredEvent } from '../event/stored-event.js';
import { RuntimeEnv } from '../runtime-env.js';
import { getAuthId } from './get-auth-id.js';
import { InstanceIdentifier } from './instance-identifier.js';
import { StateDefinitions } from '../storage/state-declaration.js';
import { HostRuntimeExecution } from '../runtime/host-runtime.js';
import { createPartitionedTransactionClient } from './create-instance-storage.js';

export async function applyRuntimeResultToInstance<
  TState extends StateDefinitions,
>(
  runtime: HostRuntimeExecution<any>,
  states: TState,
  streamStorage: InstanceStorage<TState>,
  streamId: InstanceIdentifier,
  evt: StoredEvent,
  env: RuntimeEnv,
) {
  await streamStorage.storage.transaction(async (trx) => {
    const result = await runtime.execute(
      createPartitionedTransactionClient(
        trx.storage('state'),
        ['tenant', 'realm', 'pattern', 'version', 'name'],
        {
          name: streamId.name,
          tenant: streamId.tenant,
          version: streamId.version,
          pattern: streamId.pattern,
          realm: streamId.realm,
        },
      ),
      evt,
      {
        number: 0,
        text: '', // TODO
      },
    );

    await trx.storage('processor').state('operations').insert({
      realm: streamId.realm,
      pattern: streamId.pattern,
      version: streamId.version,
      name: streamId.name,
      tenant: streamId.tenant,
      eventId: evt.id,
      result,
      appliedAt: new Date(),
      eventAt: evt.createdAt,
    });

    if (result.success) {
      await trx.storage('processor').state('eventSequence').insert({
        realm: streamId.realm,
        pattern: streamId.pattern,
        version: streamId.version,
        name: streamId.name,
        tenant: streamId.tenant,
        eventId: evt.id,
        appliedAt: new Date(),
        error: undefined,
      });

      if (result.permissions) {
        for (const permission of result.permissions) {
          const permissionAuthId = await getAuthId(permission.auth, env);
          const value = permission.type === 'grant_event';
          if (permission.permission === 'write') {
            await trx
              .storage('management')
              .state('permissions')
              .upsert({
                authId: permissionAuthId,
                name: streamId.name,
                tenant: streamId.tenant,
                version: streamId.version,
                pattern: streamId.pattern,
                realm: streamId.realm,
                type: `event:${permission.event}`,
                write: value,
                read: undefined,
              });
          } else if (permission.permission === 'read') {
            await trx
              .storage('management')
              .state('permissions')
              .upsert({
                authId: permissionAuthId,
                name: streamId.name,
                tenant: streamId.tenant,
                version: streamId.version,
                pattern: streamId.pattern,
                realm: streamId.realm,
                type: `event:${permission.event}`,
                read: value,
                write: undefined,
              });
          } else {
            await trx
              .storage('management')
              .state('permissions')
              .upsert({
                authId: permissionAuthId,
                name: streamId.name,
                tenant: streamId.tenant,
                version: streamId.version,
                pattern: streamId.pattern,
                realm: streamId.realm,
                type: `event:${permission.event}`,
                write: value,
                read: value,
              });
          }
        }
      }
    }
  });
}
