import { string } from '@aion/core/typing/string.js';
import { boolean } from '@aion/core/typing/boolean.js';
import { date } from '@aion/core/typing/date.js';
import { TypeOf } from '@aion/core/typing/type-of.js';
import { declareState } from '@aion/core/storage/declare-state.js';
import { defineState } from '@aion/core/storage/define-state.js';

export const Todo = declareState(
  {
    id: string(),
    title: string(),
    completed: boolean(),
    createdAt: date(),
  },
  ['id'] as const,
).index('date', ['createdAt']);

export type Todo = TypeOf<(typeof Todo)['type']>;

export const todoState = defineState({
  todos: Todo,
});

export type TodoState = typeof todoState;
