import { StateDefinitions } from './state-declaration.js';
import { MultiStateDefinitions } from './multi-state-definitions.js';

export function mapMultiStateDeclaration<T extends MultiStateDefinitions>(
  state: T,
): StateDefinitions {
  return Object.entries(state).reduce<StateDefinitions>(
    (map, [prefix, stateDefinitions]) => {
      for (const [stateName, stateDefinition] of Object.entries(
        stateDefinitions,
      )) {
        map[`${prefix}-${stateName}`] = stateDefinition;
      }

      return map;
    },
    {},
  );
}
