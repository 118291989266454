import { EventAuth, fallbackAuth } from '../event/event-auth.js';
import { StreamArgs } from './stream-args.js';
import { CustomError } from '../custom-error.js';

export function buildStreamKey(
  pattern: string,
  { auth, args }: { auth?: EventAuth; args: StreamArgs },
): string {
  let value = pattern.toLowerCase();

  const authValue = fallbackAuth(auth);
  value = value.replace('$auth.sub', authValue.sub);
  value = value.replace('$auth.aud', authValue.aud);
  value = value.replace('$auth.iss', authValue.iss);

  if (args) {
    for (const [key, argValue] of Object.entries(args)) {
      value = value.replace(`$args.${key.toLowerCase()}`, argValue);
    }
  }
  if (value.indexOf('$') !== -1) {
    throw new CustomError('invalid stream name', null, {
      pattern,
      value,
      args,
    });
  }
  return value;
}
