import {
  StateDeclaration,
  StateDefinition,
  StateKeyValues,
  TypeOfStateDeclaration,
} from '@aion/core/storage/state-declaration.js';
import { Lazy } from '@aion/core/management/lazy.js';
import { IDBPDatabase } from 'idb';
import { SnapshotClient } from '@aion/core/storage/snapshot-client.js';
import { parseData } from './parse-data.js';
import {
  RangeOptions,
  SnapshotIndexClient,
} from '@aion/core/storage/snapshot-index-client.js';
import { RangeResult } from '@aion/core/storage/browse-result.js';
import { createSnapshotIndexClient } from './create-snapshot-index-client.js';
import { getStateKeys } from '@aion/core/runtime/get-state-data-keys.js';
import { StateValue } from '@aion/core/storage/state-value.js';
import { getKeyRange } from './get-key-range.js';

export function createIndexeddbSnapshotClient<
  T extends StateDeclaration<any, any, any>,
>(
  dbPromise: Lazy<IDBPDatabase>,
  storeName: string,
  type: StateDefinition<T, any, any>,
): SnapshotClient<T> {
  return {
    async get(
      id: StateKeyValues<T>,
    ): Promise<TypeOfStateDeclaration<T> | null> {
      const db = await dbPromise.resolve();
      const store = db
        .transaction(storeName, 'readonly')
        .objectStore(storeName);
      const result = await store.get(getKeyRange(type, id));
      return parseData(result ?? null, type);
    },
    async *range(
      bookmark: StateKeyValues<T>,
      opts?: RangeOptions,
    ): AsyncGenerator<RangeResult<TypeOfStateDeclaration<T>>> {
      let open = opts?.open ?? false;
      const direction = opts?.dir ?? 'next';
      let currentBookmark: Record<string, StateValue> = bookmark;
      do {
        const db = await dbPromise.resolve();
        const store = db
          .transaction(storeName, 'readonly')
          .objectStore(storeName);
        const range: IDBKeyRange | undefined =
          currentBookmark !== null && currentBookmark !== undefined
            ? direction === 'prev'
              ? IDBKeyRange.upperBound(currentBookmark, open)
              : IDBKeyRange.lowerBound(currentBookmark, open)
            : undefined;

        const batchSize = 10;
        let cursor = await store.openCursor(range, direction);

        const batch: RangeResult<TypeOfStateDeclaration<T>>[] = [];
        while (cursor && batch.length < batchSize) {
          if (!cursor.value) {
            for (const item of batch) {
              yield item;
            }
            return;
          }

          const data = parseData(cursor.value, type);
          batch.push({
            keys: getStateKeys(type, data),
            data,
          });
          cursor = await cursor.continue();
        }
        for (const item of batch) {
          yield item;
          currentBookmark = item.keys;
          open = true;
        }
        if (!cursor) {
          return;
        }
      } while (true);
    },
    index<K extends keyof T['indices'] & string>(
      index: K,
    ): SnapshotIndexClient<T, T['indices'][K]> {
      const indexType = type.indices[index];
      return createSnapshotIndexClient(
        dbPromise,
        storeName,
        type,
        index,
        indexType,
        [],
      );
    },
  };
}
