import { InjectedEvent } from './aggregation-definition.js';
import { StreamDefinition } from './stream-definition.js';

export function injectEvent<
  T extends StreamDefinition<any, any, any, any, any>,
  K extends keyof T['events'] & string,
>(stream: { source: T }, key: K): InjectedEvent<T['events'][K], T['auth']> {
  return {
    stream: { version: stream.source.version, pattern: stream.source.pattern },
    type: stream.source.events[key],
    event: key,
    auth: stream.source.auth,
  };
}
