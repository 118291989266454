import { createType } from './create-type.js';
import { DateTypeDescription } from './date-type-description.js';
import { ValidateError } from './validate-error.js';

export const date = () =>
  createType<Date, DateTypeDescription>({
    description: { type: 'date' },
    validate: (val, path) => {
      if (val instanceof Date) {
        return { success: true, data: val };
      } else if (Object.prototype.toString.call(val)) {
        return { success: true, data: new Date(val as Date) };
      } else if (typeof val === 'string') {
        const date = new Date(val);
        if (!isNaN(date.getTime())) {
          return { success: true, data: date };
        } else {
          return {
            success: false,
            error: new ValidateError(
              'not a valid date at ' + path.join('->'),
              path,
              val,
            ),
          };
        }
      } else {
        return {
          success: false,
          error: new ValidateError(
            'not a valid date at ' + path.join('->'),
            path,
            val,
          ),
        };
      }
    },
  });
