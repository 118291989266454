import { EventAuth, fallbackAuth } from '../event/event-auth.js';
import { buildStreamKey } from '../stream/build-stream-name.js';
import { createLazy } from './create-lazy.js';
import { AuthIdentifier, InstanceIdentifier } from './instance-identifier.js';
import { getAuthId } from './get-auth-id.js';
import { RuntimeEnv } from '../runtime-env.js';
import { Lazy } from './lazy.js';
import { StreamArgs } from '../stream/stream-args.js';
import { DefinitionIdentifier } from '../realm/definition-identifier.js';

export function createLazyInstanceIdentifier(opts: {
  type: 'stream' | 'aggregation';
  identifier: Lazy<DefinitionIdentifier>;
  args: StreamArgs | undefined;
  auth: EventAuth | undefined;
  env: RuntimeEnv;
}): Lazy<InstanceIdentifier> {
  const auth = fallbackAuth(opts.auth);

  return createLazy<InstanceIdentifier>(async () => {
    const identifier = await opts.identifier.resolve();
    const name = buildStreamKey(identifier.pattern, {
      auth,
      args: opts.args ?? {},
    });

    return {
      tenant: identifier.tenant,
      realm: identifier.realm,
      version: identifier.version,
      pattern: identifier.pattern,

      args: opts.args ?? {},
      name,
      auth: {
        ...auth,
        key: await getAuthId(auth, opts.env),
      },
      type: opts.type,
      key: `${opts.type}:${name}`,
    };
  });
}

export function getManagementKey(
  type: 'stream' | 'aggregation',
  identifier: DefinitionIdentifier,
): string {
  return `${type}:${identifier.tenant}:${identifier.realm}:${identifier.pattern}:${identifier.version}`;
}

export function createLazyAuthIdentifier(opts: {
  auth: EventAuth | undefined;
  env: RuntimeEnv;
}) {
  const auth = fallbackAuth(opts.auth);

  return createLazy<AuthIdentifier>(async () => {
    return {
      ...auth,
      key: await getAuthId(auth, opts.env),
    };
  });
}
