import { Lock } from './lock.js';

export function createMemoryLock(): Lock {
  const locks: Record<string, Promise<unknown>> = {};

  return {
    acquire<T>(key: string, fn: () => Promise<T>): Promise<T> {
      const existing = locks[key];
      if (existing) {
        const chain = existing.then(fn);
        locks[key] = chain.catch();
        return chain;
      } else {
        const chain = fn();
        locks[key] = chain.catch();
        return chain;
      }
    },
  };
}
