import { MultiStateDefinitions } from './multi-state-definitions.js';
import { StorageBuilder } from './tracker/storage-builder.js';
import { MultiStorage } from './multi-storage.js';
import { TrackedStorage } from './tracker/tracked-state.js';
import { createMultiTrackedStorage } from './create-multi-tracked-storage.js';
import { MultiStorageTransactionClient } from './multi-storage-transaction-client.js';
import { TransactionClient } from './storage-interface.js';
import { createMultiTransactionClient } from './create-multi-transaction-client.js';
import { mapMultiStateDeclaration } from './map-multi-state-declaration.js';

export function createMultiStorage<TState extends MultiStateDefinitions>(
  storageBuilder: StorageBuilder,
  name: string,
  state: TState,
): MultiStorage<TState> {
  const multiStorageState = mapMultiStateDeclaration(state);
  const storage = storageBuilder.open(name, multiStorageState);

  return {
    storage<K extends keyof TState & string>(
      key: K,
    ): TrackedStorage<TState[K]> {
      return createMultiTrackedStorage(key, storage);
    },
    transaction<T>(
      fn: (client: MultiStorageTransactionClient<TState>) => Promise<T>,
    ): Promise<T> {
      return storage.transaction(async (client) => {
        return await fn({
          storage<K extends keyof TState & string>(
            key: K,
          ): TransactionClient<TState[K]> {
            return createMultiTransactionClient<TState[K]>(key, client as any);
          },
        });
      });
    },
  };
}
