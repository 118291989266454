import { createType } from './create-type.js';
import { NumberAsTextTypeDescription } from './number-as-text-type-description.js';
import { ValidateError } from './validate-error.js';

export const numberAsText = () =>
  createType<number, NumberAsTextTypeDescription>({
    description: { type: 'numberAsText' },
    validate: (val, path) => {
      if (typeof val === 'string') {
        const num = parseInt(val);
        if (!isNaN(num)) {
          return { success: true, data: num };
        } else {
          return {
            success: false,
            error: new ValidateError('not a valid number', path, val),
          };
        }
      } else {
        return {
          success: false,
          error: new ValidateError('not a valid string', path, val),
        };
      }
    },
  });
