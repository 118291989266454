import { Type } from './type.js';
import { SafeParseReturnType } from './safe-parse-return-type.js';

export function lazyType<T extends Type<any, any>>(fn: () => T): T {
  return {
    _output: null as any,
    description: new Proxy(
      {},
      {
        get(target: {}, p: string | symbol): any {
          const resolved = fn();
          return resolved.description[p];
        },
      },
    ),
    safeParse(
      data: unknown,
      path: string[],
    ): SafeParseReturnType<T['_output']> {
      return fn().safeParse(data, path);
    },
  } as T;
}
