import { Lazy } from '@aion/core/management/lazy.js';
import { IDBPDatabase } from 'idb';
import { StateDefinitions } from '@aion/core/storage/state-declaration.js';
import { TransactionOperation } from '@aion/core/storage/transaction-operation.js';
import { distinctArray } from './distinct-array.js';
import { CustomError } from '@aion/core/custom-error.js';
import { patch } from './patch.js';
import { insert } from './insert.js';
import { del } from './del.js';
import { upsert } from './upsert.js';

export async function apply(
  lazyDb: Lazy<IDBPDatabase>,
  types: StateDefinitions,
  operations: TransactionOperation[],
) {
  const storeNames = operations.map((o) => o.state);
  const uniqueStoreNames = distinctArray(storeNames);
  const db = await lazyDb.resolve();
  const trx = db.transaction(uniqueStoreNames, 'readwrite');
  for (const operation of operations) {
    const declaration = types[operation.state];
    if (!declaration) {
      throw new CustomError(`state not found`, null, {
        state: operation.state,
      });
    }

    try {
      switch (operation.type) {
        case 'patch':
          await patch(trx, operation.state, declaration, operation.data);
          break;
        case 'insert':
          await insert(trx, operation.state, declaration, operation.data);
          break;
        case 'delete':
          await del(trx, operation.state, declaration, operation.keys);
          break;
        case 'upsert':
          await upsert(trx, operation.state, declaration, operation.data);
      }
    } catch (e) {
      if (e instanceof Error) {
        if (e.message.match(/Key already exists in the object store/)) {
          throw new CustomError(`duplicate key error`, e, {
            keys: operation.keys,
            state: operation.state,
          });
        }
      }
      throw new CustomError(`transaction error`, e, {});
    }
  }
}
