import { RpcDefinitions, RpcEndpoint } from './server.js';

export type HttpRpcDefinition<Rpc extends RpcDefinitions> = {
  [Prop in keyof Rpc]: HttpEndpoint<Rpc[Prop]>;
};

export type HttpSource =
  | { type: 'body' }
  | { type: 'query' }
  | { type: 'path' }
  | { type: 'header' };

export interface HttpEndpoint<Rpc extends RpcEndpoint> {
  method: 'get' | 'post';
  path: string;
  mapping: { [Prop in keyof Rpc['input']['_output']]: HttpSource };
  openapi: Partial<{
    operationId: string;
    description: string;
    tags: string[];
  }>;
}

export function defineHttpRpc<
  Rpc extends RpcDefinitions,
  Http extends HttpRpcDefinition<Rpc>,
>(rpc: Rpc, http: Http): HttpRpcDefinition<Rpc> {
  return http;
}
