import { inject, Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { SnapshotClient } from '@aion/core/storage/snapshot-client.js';

@Injectable()
export class AionStateService {
  private router = inject(Router);

  // async debug(runner: OfflineInstance<any, any, any, any>) {
  //   await this.router.navigate([{ outlets: { modal: [ 'debug', runner.storageName ] }}], {})
  // }

  async getRegisteredStateNames(name: string): Promise<string[]> {
    return [];
  }

  getAppState(storageName: string, stateNames: string): SnapshotClient<any> {
    throw new Error('not impl'); // TODO implement
  }

  getRunnerState(name: string): SnapshotClient<any> {
    throw new Error('not impl'); // TODO implement
  }
}
