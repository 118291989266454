export function transformMap<S, T>(
  map: { [key: string]: S },
  transform: (value: S) => T,
): { [key: string]: T } {
  const result: { [key: string]: T } = {};
  for (const [key, value] of Object.entries(map)) {
    result[key] = transform(value);
  }
  return result;
}
