import { object } from '../typing/object.js';
import { string } from '../typing/string.js';
import { number } from '../typing/number.js';
import { TypeOf } from '../typing/type-of.js';

export const EventRandomSeed = object('EventRandomSeed', {
  text: string(),
  number: number(),
});
export type EventRandomSeed = TypeOf<typeof EventRandomSeed>;
