import { StateDefinitions } from '@aion/core/storage/state-declaration.js';
import { SnapshotClient } from '@aion/core/storage/snapshot-client.js';
import { createRemoteSnapshotClient } from './create-remote-snapshot-client.js';
import { ObservedClient } from '@aion/core/storage/observer/observed-client.js';
import { createRemoteObservedClient } from './create-remote-observed-client.js';
import { RemoteDescription } from './remote-description.js';
import { RpcClient } from '../api/create-rpc-client.js';
import { SessionManager } from '../auth/jwt-session.js';
import { Lazy } from '@aion/core/management/lazy.js';
import { AggregateInterface } from '@aion/core/management/aggregate-interface.js';

export function createRemoteAggregationInterface<
  TState extends StateDefinitions,
>(
  state: TState,
  rpc: RpcClient,
  session: SessionManager,
  lazyVersion: Lazy<string>,
  streamIdentifier: RemoteDescription,
): AggregateInterface<TState> {
  return {
    state,
    observe<K extends string & keyof TState>(
      name: K,
    ): ObservedClient<TState[K]> {
      return createRemoteObservedClient(
        state[name]!,
        rpc,
        session,
        lazyVersion,
        'aggregation',
        streamIdentifier,
        name,
      );
    },
    snapshot<K extends string & keyof TState>(
      name: K,
    ): SnapshotClient<TState[K]> {
      return createRemoteSnapshotClient(
        state[name]!,
        rpc,
        session,
        lazyVersion,
        'aggregation',
        streamIdentifier,
        name,
      );
    },
  };
}
