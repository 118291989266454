import { GroupContext } from '../context';
import { AcceptInviteEvent } from './accept-invite.event';

export async function acceptInvite(evt: AcceptInviteEvent, ctx: GroupContext) {
  await ctx.state('groupMembers').patch({
    id: evt.groupMemberId,
    state: 'accepted',
  });
  if (ctx.metadata.auth) {
    ctx.stream.revokeWrite(ctx.metadata.auth, ['accept-invite']);
    ctx.stream.allowRead(ctx.metadata.auth, ['accept-invite', 'create-group']);
  }
}
