import {
  ChangeDetectionStrategy,
  Component,
  DestroyRef,
  inject,
} from '@angular/core';
import { TodoStateService } from '../todo-state.service';
import { AionStateService } from '../aion/aion-state.service';
import { ActivatedRoute, Params } from '@angular/router';
import { todoStream } from '../../state/todo/stream';
import { takeUntilDestroyed, toSignal } from '@angular/core/rxjs-interop';
import { groupStream } from '../../state/group/stream.js';
import { fromAsyncIterable } from '../signal-from-destroy-ref.js';
import { map, pipe, shareReplay, switchMap, tap } from 'rxjs';
import { StreamInterface } from '@aion/core/management/stream-interface.js';
import { TodoState } from '../../state/todo/state.js';
import { TodoEvents } from '../../state/todo/events.js';

@Component({
  selector: 'app-todo-page',
  templateUrl: './todo-page.component.html',
  styleUrls: ['./todo-page.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TodoPageComponent {
  todoState = inject(TodoStateService);
  aion = inject(AionStateService);
  route = inject(ActivatedRoute);
  destroyRef = inject(DestroyRef);

  stream$ = this.route.params.pipe(
    map<Params, StreamInterface<TodoState, TodoEvents>>((params) => {
      if (!params || params['group'] === 'personal') {
        return this.todoState.management.getStream(todoStream, {});
      } else {
        return this.todoState.management.getStream(groupStream, {
          args: {
            groupId: params['group'],
          },
        }) as any; // TODO typing
      }
    }),
  );
  stream = toSignal(this.stream$);

  todos$ = this.stream$.pipe(
    switchMap((stream) =>
      fromAsyncIterable((signal) =>
        stream.observe('todos').index('date').observeRange(null, {
          take: 10,
          dir: 'prev',
          signal,
        }),
      ),
    ),
    takeUntilDestroyed(this.destroyRef),
    shareReplay(1),
  );

  todos = toSignal(this.todos$);
}
