import { Emitter } from './emitter.js';
import { Listener } from './listener.js';
import { Hook } from './hook.js';
import { createLazy } from '../management/create-lazy.js';
import { Runnable } from '../queue/runnable.js';

export function mapHook<T, R>(hook: Hook<T>, map: (val: T) => R): Hook<R> {
  return {
    on(cb: Listener<R>) {
      return hook.on((res) => cb(map(res)));
    },
  };
}

export function delayedHook<T>(fn: () => Promise<Hook<T>>): Hook<T> {
  const lazyHook = createLazy(fn);
  return {
    on(cb: Listener<T>): Runnable<void> {
      const resolved = lazyHook.resolve().then((hook) => {
        return hook.on(cb);
      });
      return {
        ready: resolved.then(() => ({
          value: undefined,
          done: Promise.resolve(),
        })),
      };
    },
  };
}

export function createEmitter<T>(): Emitter<T> {
  const listeners: Listener<T>[] = [];

  return {
    on: (cb: Listener<T>) => {
      listeners.push(cb);
    },
    emit(val: T) {
      for (const listener of listeners) {
        listener(val);
      }
    },
  };
}
