import { defineHttpRpc } from '@aion/core/rpc/server/http-rpc.js';
import { rpcManagementDefinition } from './rpc-management-definition.js';

export const rpcManagementHttpDefinition = defineHttpRpc(
  rpcManagementDefinition,
  {
    upload: {
      method: 'post',
      path: `/api/{tenant}/upload/{name}`,
      mapping: {
        tenant: { type: 'path' },
        name: { type: 'path' },
        auths: { type: 'body' },
        aggregations: { type: 'body' },
        streams: { type: 'body' },
        token: { type: 'header' },
      },
      openapi: {
        tags: ['management'],
        operationId: 'uploadApp',
        description: 'Upload an app definition',
      },
    },
  },
);
