import { object } from '../typing/object.js';
import { string } from '../typing/string.js';
import { literal } from '../typing/literal.js';
import { union } from '../typing/union.js';
import { TypeOf } from '../typing/type-of.js';
import { record } from '../typing/record.js';

export const InstanceType = union('InstanceType', [
  literal('stream'),
  literal('aggregation'),
]);

export const InstanceIdentifier = object('InstanceIdentifier', {
  tenant: string(),
  realm: string(),
  version: string(),
  pattern: string(),
  name: string(),
  type: InstanceType,
  auth: object('AuthId', {
    sub: string(),
    aud: string(),
    iss: string(),
    key: string(),
  }),
  args: record(string(), string()),
});
export type InstanceIdentifier = TypeOf<typeof InstanceIdentifier>;

export interface AuthIdentifier {
  key: string;
  sub: string;
  iss: string;
  aud: string;
}
