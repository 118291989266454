import { jwtStore } from '@aion/core/realm/jwt-store.js';
import { secret } from '@aion/core/realm/secret.js';
import { createGoogleOAuth } from '@aion/core/realm/google.js';
import { createMicrosoftOAuth } from '@aion/core/realm/microsoft.js';
import { defineRealm } from '@aion/core/realm/define-realm.js';

export const todoRealm = defineRealm('todo', {
  auth: {
    custom: jwtStore({
      issuer: 'custom',
      secret: '1234',
      audience: 'custom',
    }),
    microsoft: createMicrosoftOAuth({
      clientId: 'b7cd7686-6e5c-4224-a1d3-b974f00635c7',
      clientSecret: secret('MICROSOFT_CLIENT_SECRET'),
      tenant: 'common',
    }),
    google: createGoogleOAuth({
      clientId:
        '447270742011-odnu7a0jj7gbocaffjut1t4uhd6eurts.apps.googleusercontent.com',
      clientSecret: secret('GOOGLE_CLIENT_SECRET'),
    }),
  },
});
