import { Component, inject, OnInit, signal } from '@angular/core';
import { AionStateService } from '../aion-state.service';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-state-list',
  templateUrl: './state-list.component.html',
  styleUrls: ['./state-list.component.scss'],
})
export class StateListComponent implements OnInit {
  private aionState = inject(AionStateService);
  private route = inject(ActivatedRoute);

  stateNames = signal<string[]>([]);

  ngOnInit() {
    const storageName = this.route.snapshot.params['storageName'];
    this.aionState.getRegisteredStateNames(storageName).then((stateNames) => {
      this.stateNames.set(stateNames);
    });
  }
}
