import { ChangeDetectionStrategy, Component, inject } from '@angular/core';
import { TodoStateService } from '../todo-state.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LoginComponent {
  todoState = inject(TodoStateService);

  loginWithGoogle() {
    return this.todoState.management.session.navigateToLogin('google');
  }

  loginWithMicrosoft() {
    return this.todoState.management.session.navigateToLogin('microsoft');
  }
}
