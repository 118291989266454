import {
  rpcBrowserClient,
  RpcBrowserClientOptions,
} from '@aion/core/rpc/client/client.js';
import { rpcApiDefinition } from './rpc-api-definition.js';
import { rpcApiHttpDefinition } from './rpc-api-http-definition.js';
import { rpcManagementDefinition } from './rpc-management-definition.js';
import { rpcManagementHttpDefinition } from './rpc-management-http-definition.js';

export function createRpcClient(opts: RpcBrowserClientOptions) {
  return rpcBrowserClient(rpcApiDefinition, rpcApiHttpDefinition, opts);
}

export type RpcClient = ReturnType<typeof createRpcClient>;

export function createRpcManagementClient(opts: RpcBrowserClientOptions) {
  return rpcBrowserClient(
    rpcManagementDefinition,
    rpcManagementHttpDefinition,
    opts,
  );
}

export type RpcManagementClient = ReturnType<typeof createRpcManagementClient>;
