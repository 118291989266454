import { Component, inject } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-runner-detail',
  templateUrl: './runner-detail.component.html',
  styleUrls: ['./runner-detail.component.scss'],
})
export class RunnerDetailComponent {
  private route = inject(ActivatedRoute);

  storageName?: string;

  ngOnInit() {
    this.storageName = this.route.snapshot.params['storageName'];
  }
}
