import { CanActivateFn } from '@angular/router';
import { inject } from '@angular/core';
import { AionStateService } from './aion-state.service';

export const existingRunnerGuard: CanActivateFn = async (route, state) => {
  const aion = inject(AionStateService);
  const runner = await aion.getRegisteredStateNames(
    route.params['storageName'],
  );
  return !!runner && runner.length > 0;
};
